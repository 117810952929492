/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';
import {
  Col, Form, FormControl, InputGroup, Row,
} from 'react-bootstrap';
import * as yup from 'yup';
import styles from './DurationPicker.module.css';
import { DurationFilter } from '../../filterTypes';
import { FilterSection, FilterSectionComponent, useFilterSection } from '../../useFilter';

const schema = yup.object().shape({
  minDuration: yup.number().label('Min duration').positive().integer(),
  maxDuration: yup.number().label('Max duration').positive().integer()
    .test(
      'largerThanMinDuration',
      'Max duration must be greater than or equal to Min duration.',
      function validate(item) {
        if (this.parent.minDuration === undefined || this.parent.maxDuration === undefined) {
          return true;
        }

        return parseInt(this.parent.maxDuration!, 10) >= parseInt(this.parent.minDuration!, 10);
      },
    ),
});

const DurationPicker: FilterSectionComponent<DurationFilter> = ({ values }, ref) => {
  const [state, setState] = useState<DurationFilter>({});
  const [errors, setErrors] = useState<string[] | null>(null);

  useFilterSection<DurationFilter>(ref, () => ({
    getValues: () => {
      console.log(state);
      try {
        schema.validateSync(state);
        setErrors(null);
        return state;
      } catch (e: any) {
        setErrors(e.errors);
        return false;
      }
    },
  }));

  useEffect(() => {
    setState({
      minDuration: values.minDuration,
      maxDuration: values.maxDuration,
    });
  }, [values]);

  return (
    <div>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column xs={5}>
          Min duration
        </Form.Label>
        <Col xs={7}>
          <InputGroup>
            <FormControl
              type="number"
              value={state.minDuration || ''}
              onChange={(e) => setState({
                ...state,
                minDuration: e.target.value || undefined,
              })}
            />
            <InputGroup.Text>days</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column xs={5}>
          Max duration
        </Form.Label>
        <Col xs={7}>
          <InputGroup>
            <FormControl
              type="number"
              value={state.maxDuration || ''}
              onChange={(e) => setState({
                ...state,
                maxDuration: e.target.value || undefined,
              })}
            />
            <InputGroup.Text>days</InputGroup.Text>
          </InputGroup>
        </Col>
      </Form.Group>
      <Row>
        {errors !== null && Object.values(errors).map((error) => (
          <div
            className={styles.error}
            key={error}
          >
            {error}
          </div>
        ))}
      </Row>
    </div>
  );
};

const section : FilterSection<DurationFilter> = {
  name: 'duration',
  title: 'Duration',
  Component: forwardRef(DurationPicker),
  popupWidth: 350,
  extractState: (values) => ({
    minDuration: values.minDuration,
    maxDuration: values.maxDuration,
  }),
  shortDescription: (values) => {
    if (values.minDuration && values.maxDuration) {
      return `Between ${values.minDuration}-${values.maxDuration} days`;
    } if (values.minDuration) {
      return `At least ${values.minDuration} days`;
    } if (values.maxDuration) {
      return `At most ${values.maxDuration} days`;
    }

    return null;
  },
};

export default section;
