import React, { FunctionComponent, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useLocation } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styles from './SaveCurrentSearch.module.css';
import FilterButton from '../../FilterButton/FilterButton';
import { getApiInstance } from '../../../../../utils/api';
import { QueryParamsType } from '../../../../../myApi';

type FormData = {
  name: string;
};

const SaveCurrentSearch: FunctionComponent = () => {
  // TODO: handle form errors
  const {
    register, handleSubmit, setValue, formState: { errors },
  } = useForm<FormData>();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSubmit = async (data: FormData) => {
    let { search } = location;

    // Remove leading "?"
    if (search.length > 0) {
      search = search.substring(1, search.length);
    }

    const api = getApiInstance();

    // Hack to make it possible to pass the query params as a string
    // @ts-ignore
    api.toQueryString = (rawQuery?: QueryParamsType) => search;

    // TODO: handle errors
    try {
      const response = await api.api.savedSearchCreate({ name: data.name }, { });

      setValue('name', '');
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <FilterButton onClick={handleShow}>Save this search</FilterButton>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Save this search</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Search name</Form.Label>
              <Form.Control {...register('name', { required: true })} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Create saved search
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>

  );
};

export default SaveCurrentSearch;
