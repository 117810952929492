import React, {
  FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { Form } from 'react-bootstrap';
import styles from './LocationPicker.module.css';
import FilterPopup from '../FilterPopup/FilterPopup';
import { Api, GeocodingResultDto } from '../../../../../myApi';
import { useDebounce } from '../../../../../utils/hooks';
import { API_URL } from '../../../../../utils/constants';
import Location from '../../LocationPicker/Location';
import { AssignmentFilter, LocationFilter } from '../../filterTypes';
import Input from './Input';

const anywhere : GeocodingResultDto = {
  name: 'Anywhere',
};

type Props = {
  onChange: (filter: AssignmentFilter) => void;
  values: LocationFilter;
};

type ContentProps = {
  isModal: boolean;
  query: string;
  onChange: (newQuery: string) => void;
  results: GeocodingResultDto[];
  onLocationPicked: (newLocation: GeocodingResultDto) => void;
  show: boolean;
};

const Content : FunctionComponent<ContentProps> = ({
  isModal, query, onChange, results, onLocationPicked, show,
}) => (
  <div className={styles.content}>
    {isModal && (
    <Input
      isModal
      isInsideModal
      show={show}
      query={query}
      onChange={onChange}
    />
    )}
    <div className={styles.results}>
      {results.map((x, index) => (
        <Location
          key={`${index}`}
          onClick={() => onLocationPicked(x)}
          text={x.name!}
          type={x.type!}
        />
      ))}
    </div>
  </div>
);

const LocationPicker: FunctionComponent<Props> = ({ onChange, values }) => {
  const [show, setShow] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<GeocodingResultDto[]>([]);
  const visibleInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setQuery(values.location || '');
  }, [values]);

  const invokeDebounced = useDebounce(
    () => {
      if (show && (query === '' || isDirty)) {
        const api = new Api({
          baseUrl: API_URL,
        });
        const fetchData = async () => {
          const response = await api.api.placesSuggestionsList({ query });
          setResults(response.data);
        };
        fetchData().catch(console.error);
      }
    },
    200,
  );
  useEffect(invokeDebounced, [query, invokeDebounced, show, isDirty]);

  const pickLocation = (location: GeocodingResultDto) => {
    setQuery(location.name!);
    setShow(false);
    setResults([location]);
    setIsDirty(false);

    const newValues: AssignmentFilter = {
      location: location.name!,
      country: location.country!,
      locationType: location.type!,
    };

    if (location.name !== 'Anywhere') {
      newValues.nELat = location.boundingBoxMax?.lat;
      newValues.nELng = location.boundingBoxMax?.lng;
      newValues.sWLat = location.boundingBoxMin?.lat;
      newValues.sWLng = location.boundingBoxMin?.lng;
    } else {
      newValues.nELat = undefined;
      newValues.nELng = undefined;
      newValues.sWLat = undefined;
      newValues.sWLng = undefined;
    }

    onChange(newValues);
  };

  const handleChange = (newQuery: string) => {
    setQuery(newQuery);
    setIsDirty(true);
  };

  return (
    <FilterPopup
      popupWidth={500}
      title="Location"
      show={show}
      setShow={setShow}
      renderContent={(isModal) => (
        <Content
          isModal={isModal}
          query={query}
          onChange={handleChange}
          results={results}
          onLocationPicked={(newLocation) => pickLocation(newLocation)}
          show={show}
        />
      )}
      renderControl={(isModal) => (
        <Input
          isModal={isModal}
          isInsideModal={false}
          onFocus={() => setShow(true)}
          query={query}
          onChange={handleChange}
          show={show}
        />
      )}
    />
  );
};

export default LocationPicker;
