import React, { FunctionComponent, useContext, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button, Container, Form } from 'react-bootstrap';
// @ts-ignore
import Admonition from 'react-admonitions';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import Layout from '../../components/Layout/Layout';
import { API_INSTANCE } from '../../utils/api';
import { AuthContext } from '../../utils/context/authContext';

type FormData = {
  email: string;
  password: string;
};

const Login: FunctionComponent = () => {
  const {
    register, handleSubmit, watch, formState: { errors },
  } = useForm<FormData>();
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  });

  const onSubmit = async (data: FormData) => {
    try {
      const response = await API_INSTANCE.api.authLoginCreate(data);
      setIsLoggedIn(true);
    } catch (e) {
      // Handle later
    }
  };

  return (
    <Layout>
      <Container className={styles.container}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" {...register('email', { required: true })} />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" {...register('password', { required: true })} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Container>
    </Layout>
  );
};

export default Login;
