/* eslint-disable react/jsx-one-expression-per-line */
import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ReactMarkdown from 'react-markdown';
import styles from './WebsiteComparisonDetail.module.css';
import { PlatformNoId } from '../../../utils/platformTypes';
import WebsiteSummaryTable from '../WebsiteSummaryTable/WebsiteSummaryTable';
import { getPlatformLink } from '../../../utils/platforms';

type Props = {
  website: PlatformNoId;
  order: number,
};

const WebsiteComparisonDetail: FunctionComponent<Props> = ({ website, order }) => (
  <div>
    <h3 id={website.slug}>
      {order}. {website.name}
      <img src={website.image} alt={`Logo ${website.name}`} className={styles.logo} />
      review
    </h3>
    <WebsiteSummaryTable website={website} />
    <div>
      <ReactMarkdown>
        {`**${website.name}** ${website.info!.summary}`}
      </ReactMarkdown>
    </div>
  </div>
);

export default WebsiteComparisonDetail;
