import React, { FunctionComponent, useMemo, useState } from 'react';
import { AuthContext, defaultState } from '../../utils/context/authContext';

const AuthProvider: FunctionComponent = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(defaultState.isLoggedIn);

  const value = useMemo(() => ({
    isLoggedIn,
    setIsLoggedIn,
  }), [isLoggedIn, setIsLoggedIn]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
