import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import debounce from 'lodash.debounce';

/**
 * Hook that alerts clicks outside of the passed ref
 */
// eslint-disable-next-line import/prefer-default-export
export function useOutsideAlerter(ref: any, onClick: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);
}

function useIsMounted() {
  const isMountedRef = useRef(true);
  useEffect(() => () => {
    isMountedRef.current = false;
  }, []);
  return () => isMountedRef.current;
}

export function useDebounce(cb: any, delay: number) {
  const options = {
    leading: false,
    trailing: true,
  };
  const inputsRef = useRef(cb);
  const isMounted = useIsMounted();
  useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(
      (...args: any) => {
        // Don't execute callback, if (1) component in the meanwhile
        // has been unmounted or (2) delay has changed
        if (inputsRef.current.delay === delay && isMounted()) inputsRef.current.cb(...args);
      },
      delay,
      options,
    ),
    [delay, debounce],
  );
}

export type Signal = number | null;

export function useSignal() : [Signal, () => void] {
  const [signalValue, setSignalValue] = useState<Signal>(null);
  const triggerSignal = useCallback(() => {
    setSignalValue((prev) => (prev === null ? 0 : prev + 1));
  }, []);

  return [signalValue, triggerSignal];
}

export function useRunOnSignal(func: () => void, signal: Signal) {
  useEffect(() => {
    if (signal !== null) {
      func();
    }
  }, [signal]);
}

function fixHeight() {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export function useResizeWindow() {
  useEffect(() => {
    // reset the height whenever the window's resized
    window.addEventListener('resize', fixHeight);

    // called to initially set the height.
    fixHeight();

    return function cleanup() {
      window.removeEventListener('resize', fixHeight);
    };
  }, []);
}

export function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    const trackWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', trackWidth);
    trackWidth();

    return function Cleanup() {
      window.removeEventListener('resize', trackWidth);
    };
  }, []);

  return windowWidth;
}
