import React, { FunctionComponent } from 'react';
import styles from './FilterButton.module.css';

type Props = {
  isActive?: boolean,
} & JSX.IntrinsicElements['button'];

const FilterButton: FunctionComponent<Props> = ({ children, isActive, ...props }) => {
  const activeClass = isActive ? styles.buttonActive : '';

  return (
    <button type="button" {...props} className={`${styles.button} ${activeClass}`}>
      {children}
    </button>
  );
};

export default FilterButton;
