import React, { FunctionComponent } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { responsibilities } from '../../../utils/responsibilities';
import styles from './Card.module.css';
import { AssignmentReadDto, ResponsibilityId } from '../../../myApi';

const Responsibility : FunctionComponent<{ id: ResponsibilityId }> = ({ id }) => {
  const responsibility = responsibilities.find((y) => y.id === id);

  if (responsibility === undefined) {
    console.warn(`Responsibility ${id} not found`);
    return null;
  }

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="responsibility-tooltip">{responsibility.name}</Tooltip>}
    >
      <span className={styles.animalIconContainer}>
        <FontAwesomeIcon className={styles.animalIcon} icon={responsibility.icon} />
      </span>
    </OverlayTrigger>
  );
};

type Props = {
  assignment: AssignmentReadDto;
};

const Responsibilities : FunctionComponent<Props> = ({ assignment }) => {
  if (!assignment.responsibilities) {
    return null;
  }

  return (
    <>
      {assignment.responsibilities.map((x) => <Responsibility key={x} id={x} />)}
    </>
  );
};

export default Responsibilities;
