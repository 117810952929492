import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { faTimes } from '@ondrejnepozitek/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './LocationPicker.module.css';

type Props = {
  isModal: boolean;
  isInsideModal: boolean;
  query: string;
  onChange: (newQuery: string) => void;
  show: boolean;
  onFocus?: () => void;
};

const Input : FunctionComponent<Props> = ({
  query, show, onChange, isModal, isInsideModal, onFocus,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (show && isInsideModal) {
      ref.current?.focus();
    }
  }, [isInsideModal, ref, show]);

  return (
    <div className={styles.inputWrapper}>
      <Form.Control
        ref={ref}
        placeholder="Where would you like to sit?"
        onFocus={() => {
          // If we are working with modals but are outside a modal,
          // blur after focusing to make sure that the input is not focused after closing the modal
          if (isModal && !isInsideModal) {
            ref.current?.blur();
          }

          if (onFocus !== undefined) {
            onFocus();
          }
        }}
        value={query}
        onChange={(e) => {
          if (show) {
            onChange(e.target.value);
          }
        }}
        className={styles.input}
      />
      {query !== '' && (
      <FontAwesomeIcon
        className={styles.inputClear}
        icon={faTimes as any}
        onClick={() => {
          onChange('');
          ref.current?.focus();
        }}
      />
      )}
    </div>
  );
};

export default Input;
