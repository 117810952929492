import React, { FunctionComponent, useContext } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './AuthOnly.module.css';
import { AuthContext } from '../../utils/context/authContext';

const AuthOnly: FunctionComponent = ({ children }) => {
  const { isLoggedIn } = useContext(AuthContext);

  if (!isLoggedIn) {
    return null;
  }

  return <>{children}</>;
};

export default AuthOnly;
