import ReactGA from 'react-ga4';
import { ENABLE_GOOGLE_ANALYTICS, IS_PRE_RENDERING } from '../constants';

if (ENABLE_GOOGLE_ANALYTICS && !IS_PRE_RENDERING) {
  ReactGA.initialize('G-9KEWXLSS9C', {
    gtagOptions: {
      send_page_view: true,
    },
  });
}

export default function useAnalytics() {
  // Do not send any page_view events here
  // otherwise that get duplicated
  // GA can track page views automatically
  // Make sure that send_page_view is set to true so that the initial page load is tracked
}
