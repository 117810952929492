import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import qs from 'query-string';
import {
  ApiResult, AssignmentsQuery, LngLatBoundsLike, MapBounds, PaginatedAssignments,
} from '../types';
import { Api, GeoClusterDto, QueryParamsType } from '../../myApi';
import {
  API_URL, IS_PRE_RENDERING, QUERY_STRING_OPTIONS, RESULTS_PER_PAGE,
} from '../constants';
import { AssignmentFilter, LocationFilter } from '../../components/Assignments/Filter/filterTypes';
import { API_INSTANCE } from '../api';

type UseAssignmentsReturn = {
  assignments: ApiResult<PaginatedAssignments>;
  currentPage: number;
  handleChangePage: (page: number) => void;
  handleFilterChange: (filter: AssignmentFilter) => void;
  state: State;
  handleMapChange: (bounds: LngLatBoundsLike) => void;
  geoClusters: ApiResult<GeoClusterDto[]>;
  mapBounds: MapBounds;
};

type State = {
  currentPage: number,
  filter: AssignmentFilter,
};

const initialState : State = {
  currentPage: 1,
  filter: {},
};

function getInitialState(searchParams: URLSearchParams) : State {
  const state = { ...initialState };
  const query : AssignmentsQuery = qs.parse(searchParams.toString(), QUERY_STRING_OPTIONS);

  if (query.page) {
    state.currentPage = parseInt(query.page, 10);
    delete query.page;
  }

  state.filter = {
    ...query,
    sWLat: query.sWLat ? parseFloat(query.sWLat) : undefined,
    sWLng: query.sWLng ? parseFloat(query.sWLng) : undefined,
    nELat: query.nELat ? parseFloat(query.nELat) : undefined,
    nELng: query.nELng ? parseFloat(query.nELng) : undefined,
  };

  return state;
}

function getMapBounds(filter: LocationFilter) : LngLatBoundsLike | null {
  if (filter.nELat !== undefined && filter.sWLat !== undefined
    && filter.nELng !== undefined && filter.sWLng !== undefined) {
    return [filter.sWLng, filter.sWLat, filter.nELng, filter.nELat];
  }

  return null;
}

type AppState = 'initialLoad' | 'default' | 'updateUrl' | 'updateState';

function useAssignments() : UseAssignmentsReturn {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const initialStateValueConst = getInitialState(searchParams);
  const [state, setState] = useState<State>(initialStateValueConst);
  const [assignments, setAssignments] = useState<ApiResult<PaginatedAssignments>>({ data: null, isLoading: false });
  const [clusters, setClusters] = useState<ApiResult<GeoClusterDto[]>>({ data: null, isLoading: false });
  const [mapBounds, setMapBounds] = useState<MapBounds>(null);
  const location = useLocation();
  const appState = useRef<AppState>('initialLoad');

  // Redirect to a new url when state changes
  useEffect(() => {
    if (appState.current === 'updateState' || appState.current === 'initialLoad') {
      appState.current = 'default';
      setMapBounds(getMapBounds(state.filter));
    } else {
      const lngLatPrecision = 4;
      const query : AssignmentsQuery = {
        resultsPerPage: RESULTS_PER_PAGE.toString(),
        page: state.currentPage === 1 ? undefined : state.currentPage.toString(),
        ...state.filter,
        sWLat: state.filter.sWLat?.toFixed(lngLatPrecision),
        sWLng: state.filter.sWLng?.toFixed(lngLatPrecision),
        nELat: state.filter.nELat?.toFixed(lngLatPrecision),
        nELng: state.filter.nELng?.toFixed(lngLatPrecision),
      };
      const queryString = qs.stringify(query, QUERY_STRING_OPTIONS);

      navigate({
        search: `?${queryString}`,
      }, {
        replace: false,
      });

      appState.current = 'updateUrl';
    }
  }, [navigate, state]);

  useEffect(() => {
    const fetchData = async () => {
      if (appState.current === 'updateUrl') {
        appState.current = 'default';
      } else {
        const stateFromSearchParams = getInitialState(searchParams);
        setState(stateFromSearchParams);
        appState.current = 'updateState';
      }

      if (location.search === '') {
        setMapBounds(null);
      }

      const api = API_INSTANCE;

      const query : AssignmentsQuery = qs.parse(location.search, QUERY_STRING_OPTIONS);
      query.resultsPerPage = RESULTS_PER_PAGE.toString();

      if (!IS_PRE_RENDERING) {
        setAssignments((prev) => ({ ...prev, isLoading: true }));
        setClusters((prev) => ({ ...prev, isLoading: true }));

        const assignmentsPromise = api.api.assignmentList(query as any);
        const clustersPromise = api.api.assignmentClustersList(query as any);

        const response = await assignmentsPromise;
        const clustersResponse = await clustersPromise;

        // Always wait for at least X ms so that the spinner does not disappear too fast
        await new Promise((r) => {
          setTimeout(r, 200);
        });

        setAssignments((prev) => ({ ...prev, data: response.data as PaginatedAssignments, isLoading: false }));
        setClusters((prev) => ({ ...prev, data: clustersResponse.data, isLoading: false }));
      }
    };
    fetchData().catch(console.error);
  }, [location]);

  const handleChangePage = useCallback((page: number) => {
    setState((prevState) => ({ ...prevState, currentPage: page }));
  }, []);

  const handleFilterChange = useCallback((filter: AssignmentFilter) => {
    let locationChanged = false;
    setState((prevState) => {
      locationChanged = prevState.filter.location !== filter.location;
      return ({ ...prevState, filter, currentPage: 1 });
    });

    if (locationChanged) {
      setMapBounds(getMapBounds(filter));
    }
  }, []);

  const handleMapChange = useCallback((bounds: LngLatBoundsLike) => {
    setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        sWLng: bounds[0],
        sWLat: bounds[1],
        nELng: bounds[2],
        nELat: bounds[3],
        location: undefined,
        country: undefined,
      },
      currentPage: 1,
    }));
    setMapBounds(() => 'notTracked');
  }, []);

  return {
    assignments,
    currentPage: state.currentPage,
    handleChangePage,
    handleFilterChange,
    state,
    handleMapChange,
    geoClusters: clusters,
    mapBounds,
  };
}

export default useAssignments;
