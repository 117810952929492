import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styles from './Websites.module.css';
import WideLayout from '../../components/WideLayout/WideLayout';
import { platforms } from '../../utils/platforms';
import Layout from '../../components/Layout/Layout';

const Websites: FunctionComponent = () => (
  <Layout>
    <Helmet>
      <title>Websites directory</title>
    </Helmet>
    <WideLayout>
      <h1>Websites directory</h1>
      Directory of house sitting websites.
      <br />
      <br />
      <ul>
        {platforms.map((x) => (
          <li>
            <Link to={`/websites/${x.slug}/`}>
              {x.name}
            </Link>
          </li>
        ))}
      </ul>
      <br />
      We also have a <Link to="/websites-comparison/">house sitting websites comparison</Link>.
    </WideLayout>
  </Layout>
);

export default Websites;
