import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './FilterModal.module.css';
import { AssignmentFilter } from '../../filterTypes';
import { FilterSection, useFilterSections } from '../../useFilter';

type Props = {
  show: boolean;
  onHide: () => void;
  components: FilterSection[];
  values: AssignmentFilter;
  onChange: (values: AssignmentFilter) => void;
};

const FilterModal: FunctionComponent<Props> = ({
  show, onHide, components, values, onChange,
}) => {
  const [currentValues, setCurrentValues] = useState<AssignmentFilter>(values);
  const { register, getValues } = useFilterSections();

  // Update currentValues when values change
  useEffect(() => {
    setCurrentValues(values);
  }, [values]);

  const handleApply = () => {
    const newValuesNested = getValues();
    let newValues : AssignmentFilter = {};
    let hasErrors = false;
    Object.values(newValuesNested).forEach((x) => {
      if (x === false) {
        hasErrors = true;
      }

      newValues = {
        ...newValues,
        ...x,
      };
    });

    if (!hasErrors) {
      onHide();
      onChange(newValues);
    }
  };

  const handleReset = () => {
    setCurrentValues({});
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles.container}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Filters
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.content}>
        {components.map((x) => {
          const { sectionRef, ...rest } = register(x.name);

          return (
            <div key={x.title} ref={sectionRef}>
              <h3 className={styles.sectionTitle}>{x.title}</h3>
              <x.Component
                values={{ ...currentValues }}
                insideModal
                {...rest}
              />
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className={styles.footer}>
        <Button variant="secondary" onClick={handleReset}>Reset</Button>
        <Button onClick={handleApply}>Apply</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FilterModal;
