import React, { FunctionComponent, useContext } from 'react';
import classnames from 'classnames';
import { Container, Nav, Navbar as BSNavbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import { AuthContext } from '../../../utils/context/authContext';

type Props = {
  noBorder?: boolean;
};

const Navbar: FunctionComponent<Props> = ({ noBorder }) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <BSNavbar
      className={classnames(styles.container, {
        [styles.containerWithBorder]: !noBorder,
      })}
      expand="md"
    >
      <BSNavbar.Brand className={styles.logo} as={Link} to="/">
        HouseSit
        <span className={styles.logoSecondPart}>Scanner</span>
      </BSNavbar.Brand>
      <BSNavbar.Toggle aria-controls="basic-navbar-nav" />
      <BSNavbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'flex-end' }}>
        <Nav>
          <Nav.Link as={Link} className={styles.navLink} to="/">Find house sits</Nav.Link>
          <Nav.Link as={Link} className={styles.navLink} to="/how-it-works/">How it works</Nav.Link>
          <Nav.Link as={Link} className={styles.navLink} to="/about/">About us</Nav.Link>
          {isLoggedIn && <Nav.Link as={Link} className={styles.navLink} to="/logout/">Logout</Nav.Link>}
        </Nav>
      </BSNavbar.Collapse>
    </BSNavbar>
  );
};

Navbar.defaultProps = {
  noBorder: false,
};

export default Navbar;
