/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AssignmentReadDto {
  /** @format int64 */
  id?: number;
  platform?: PlatformId;
  title?: string | null;
  url?: string | null;

  /** @format date-time */
  startDate?: string | null;

  /** @format date-time */
  endDate?: string | null;

  /** @format date-time */
  foundAt?: string | null;
  location?: string | null;
  imageUrl?: string | null;

  /** @format int32 */
  applicationsCount?: number | null;
  responsibilities?: ResponsibilityId[] | null;
}

export interface AssignmentReadDtoPaginatedResult {
  data?: AssignmentReadDto[] | null;

  /** @format int32 */
  totalPages?: number;

  /** @format int32 */
  totalResults?: number;
}

export interface Coordinates {
  /** @format double */
  lat?: number;

  /** @format double */
  lng?: number;
}

export interface GeoClusterDto {
  /** @format int32 */
  count?: number;

  /** @format double */
  latitude?: number;

  /** @format double */
  longitude?: number;
}

export interface GeocodingResultDto {
  name?: string | null;
  type?: string | null;
  country?: string | null;
  center?: Coordinates;
  boundingBoxMin?: Coordinates;
  boundingBoxMax?: Coordinates;
}

/**
 * @format int32
 */
export type LocationType = 0 | 1 | 2 | 3 | 4 | 5;

/**
 * @format int32
 */
export type PlatformId = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

/**
 * @format int32
 */
export type ResponsibilityId = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface SavedSearchCreateDto {
  name?: string | null;
}

export interface SavedSearchReadDto {
  /** @format int64 */
  id?: number;
  name?: string | null;
  urlData?: string | null;
}

export interface UserLoginDto {
  email?: string | null;
  password?: string | null;
}

export interface UserRegisterDto {
  /** @format email */
  email: string;
  password: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title PetSitting.ApiService
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Assignment
     * @name AssignmentList
     * @request GET:/api/Assignment
     */
    assignmentList: (
      query?: {
        resultsPerPage?: number;
        page?: number;
        country?: string;
        location?: string;
        locationType?: LocationType;
        sWLng?: number;
        sWLat?: number;
        nELng?: number;
        nELat?: number;
        fromDate?: string;
        toDate?: string;
        minDuration?: number;
        maxDuration?: number;
        websites?: PlatformId[];
        responsibilitiesYes?: ResponsibilityId[];
        responsibilitiesNo?: ResponsibilityId[];
      },
      params: RequestParams = {},
    ) =>
      this.request<AssignmentReadDtoPaginatedResult, any>({
        path: `/api/Assignment`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Assignment
     * @name AssignmentClustersList
     * @request GET:/api/Assignment/clusters
     */
    assignmentClustersList: (
      query?: {
        resultsPerPage?: number;
        page?: number;
        country?: string;
        location?: string;
        locationType?: LocationType;
        sWLng?: number;
        sWLat?: number;
        nELng?: number;
        nELat?: number;
        fromDate?: string;
        toDate?: string;
        minDuration?: number;
        maxDuration?: number;
        websites?: PlatformId[];
        responsibilitiesYes?: ResponsibilityId[];
        responsibilitiesNo?: ResponsibilityId[];
      },
      params: RequestParams = {},
    ) =>
      this.request<GeoClusterDto[], any>({
        path: `/api/Assignment/clusters`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthRegisterCreate
     * @request POST:/api/Auth/Register
     */
    authRegisterCreate: (data: UserRegisterDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Auth/Register`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthLoginCreate
     * @request POST:/api/Auth/Login
     */
    authLoginCreate: (data: UserLoginDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Auth/Login`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Auth
     * @name AuthLogoutCreate
     * @request POST:/api/Auth/Logout
     */
    authLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/Auth/Logout`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Places
     * @name PlacesSuggestionsList
     * @request GET:/api/Places/suggestions
     */
    placesSuggestionsList: (query?: { query?: string }, params: RequestParams = {}) =>
      this.request<GeocodingResultDto[], any>({
        path: `/api/Places/suggestions`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SavedSearch
     * @name SavedSearchCreate
     * @request POST:/api/SavedSearch
     */
    savedSearchCreate: (
      data: SavedSearchCreateDto,
      query?: {
        resultsPerPage?: number;
        page?: number;
        country?: string;
        location?: string;
        locationType?: LocationType;
        sWLng?: number;
        sWLat?: number;
        nELng?: number;
        nELat?: number;
        fromDate?: string;
        toDate?: string;
        minDuration?: number;
        maxDuration?: number;
        websites?: PlatformId[];
        responsibilitiesYes?: ResponsibilityId[];
        responsibilitiesNo?: ResponsibilityId[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/api/SavedSearch`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SavedSearch
     * @name SavedSearchDelete
     * @request DELETE:/api/SavedSearch
     */
    savedSearchDelete: (query?: { id?: number }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/api/SavedSearch`,
        method: "DELETE",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SavedSearch
     * @name SavedSearchList
     * @request GET:/api/SavedSearch
     */
    savedSearchList: (params: RequestParams = {}) =>
      this.request<SavedSearchReadDto[], any>({
        path: `/api/SavedSearch`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  detail = {
    /**
     * No description
     *
     * @tags Out
     * @name DetailDetail
     * @request GET:/detail/{id}
     */
    detailDetail: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/detail/${id}`,
        method: "GET",
        ...params,
      }),
  };
}
