import React, { FunctionComponent } from 'react';
import { Badge } from 'react-bootstrap';
import styles from './Card.module.css';
import { AssignmentReadDto } from '../../../myApi';

type Props = {
  assignment: AssignmentReadDto;
};

const Badges: FunctionComponent<Props> = ({ assignment }) => {
  const now = Date.now();

  let isLastMinute = false;
  let startsInDays = 0;

  if (assignment.startDate) {
    const startsAt = new Date(assignment.startDate).getTime();
    startsInDays = Math.abs(startsAt - now) / (24 * 60 * 60 * 1000);

    if (startsInDays < 7) {
      isLastMinute = true;
    }
  }

  const foundHoursAgo = (now - new Date(assignment.foundAt!).getTime()) / (60 * 60 * 1000);
  const isNew = foundHoursAgo < 3;

  const hasApplicants = assignment.applicationsCount !== null && assignment.applicationsCount !== undefined;

  return (
    <div className={styles.badges}>
      {hasApplicants && (
        <Badge bg="light" className={styles.badge}>
          {assignment.applicationsCount}
          {' '}
          applicant
          {assignment.applicationsCount === 1 ? '' : 's'}
        </Badge>
      )}
      {isNew && <Badge bg="danger" className={styles.badge}>new</Badge>}
      {isLastMinute && <Badge bg="success" className={styles.badge}>last min</Badge>}
    </div>
  );
};

export default Badges;
