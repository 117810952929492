import React, { FunctionComponent } from 'react';
import styles from './Popup.module.css';

type Props = {
  show: boolean;
  style?: React.CSSProperties;
};

const Popup: FunctionComponent<Props> = ({ children, show, ...props }) => {
  if (!show) {
    return null;
  }

  return (
    <div className={styles.container} {...props}>
      {children}
    </div>
  );
};

export default Popup;
