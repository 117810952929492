import dataSource from '../../data/stats.json';
import {
  LocationData, LocationStats, LocationStatsContainer, LocationType,
} from './locationTypes';

export const locationsData = dataSource as LocationStatsContainer;

export const continentToName : { [key: string]: string } = {
  Africa: 'Africa',
  Europe: 'Europe',
  Asia: 'Asia',
  'North America': 'North America',
  'South America': 'South America',
  Antarctica: 'Antarctica',
  Australia: 'Australia',
};

export function getStats(continent?: string, country?: string) : LocationStats | undefined {
  if (continent !== undefined) {
    const continentStats = locationsData[continent];

    if (continentStats !== undefined) {
      if (country !== undefined) {
        const countryStats = continentStats.children?.[country];
        return countryStats;
      }
      return continentStats;
    }
  }

  return undefined;
}

export function getLocation(continent?: string, country?: string) : LocationData | undefined {
  const stats = getStats(continent, country);

  if (stats === undefined) {
    return undefined;
  }

  const continentForFilter = stats.type === 'Continent' ? continentToName[stats.name] : undefined;

  return {
    stats,
    country,
    continent,
    continentForFilter,
  };
}

// TODO: map bounds
export function locationLinkBuilder(continent?: string, country?: string) : string {
  const parts : string[] = [];

  if (continent !== undefined) {
    parts.push(`location=${continent}`);
  }

  if (country !== undefined) {
    parts.push(`country=${country}`);
  }

  return `/?${parts.join('&')}`;
}

export function getChildrenName(type: LocationType) : [string, string] {
  if (type === 'Continent') {
    return ['Country', 'Countries'];
  }

  if (type === 'Country') {
    return ['City', 'Cities'];
  }

  return ['Place', 'Places'];
}

export function getRoundedSitsCount(count: number) {
  let rounding = 10;

  if (count > 100) {
    rounding = 100;
  } else if (count > 1000) {
    rounding = 1000;
  }

  const roundedNumber = Math.round(count / rounding) * rounding;

  if (roundedNumber < rounding) {
    return `<${rounding}`;
  }

  return `${roundedNumber}+`;
}
