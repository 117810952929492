/* eslint-disable react/prop-types */
import React, { useState, forwardRef, useEffect } from 'react';
import { DayPickerRangeController, FocusedInputShape, isInclusivelyAfterDay } from 'react-dates';
import moment from 'moment';
import styles from './DatePicker.module.css';
import { FilterSection, FilterSectionComponent, useFilterSection } from '../../useFilter';
import { DatesFilter } from '../../filterTypes';

type State = {
  fromDate: moment.Moment | null;
  toDate: moment.Moment | null;
};

const initialState : State = {
  fromDate: null,
  toDate: null,
};

const DatePicker: FilterSectionComponent<DatesFilter> = ({ values, insideModal }, ref) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>('startDate');
  const [state, setState] = useState(initialState);

  useFilterSection<DatesFilter>(ref, () => ({
    getValues: () => ({
      fromDate: state.fromDate?.format('YYYY-MM-DD'),
      toDate: state.toDate?.format('YYYY-MM-DD'),
    }),
  }));

  useEffect(() => {
    setState({
      fromDate: values.fromDate === undefined ? null : moment(values.fromDate),
      toDate: values.toDate === undefined ? null : moment(values.toDate),
    });
  }, [values]);

  return (
    <div
      className={insideModal ? undefined : styles.pickerContainer}
      style={{ height: insideModal ? '100%' : 'default' }}
    >
      <DayPickerRangeController
        onDatesChange={(e) => {
          setState({
            ...state,
            fromDate: e.startDate,
            toDate: e.endDate,
          });
        }}
        onFocusChange={(e) => setFocusedInput(!e ? 'startDate' : e)}
        focusedInput={focusedInput}
        startDate={state.fromDate}
        endDate={state.toDate}
        initialVisibleMonth={null}
        numberOfMonths={insideModal ? 3 : 2}
        noBorder
        hideKeyboardShortcutsPanel
        isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
        orientation={insideModal ? 'verticalScrollable' : 'horizontal'}
      />
    </div>
  );
};

const section : FilterSection<DatesFilter> = {
  name: 'dates',
  title: 'Dates',
  Component: forwardRef(DatePicker),
  popupWidth: 620,
  extractState: (values) => ({
    fromDate: values.fromDate,
    toDate: values.toDate,
  }),
  shortDescription: (values) => {
    const hasValues = values.fromDate !== undefined && values.toDate !== undefined;

    if (hasValues) {
      return (
        <>
          {moment(values.fromDate).format('DD MMM')}
          {' - '}
          {moment(values.toDate).format('DD MMM')}
        </>
      );
    }
    return null;
  },
};

export default section;
