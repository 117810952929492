import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import { colors, icons } from '../../../ResponsibilitiesPicker/constants';
import styles from '../ResponsibilitiesPicker.module.css';

type BulkButtonProps = {
  onClick: (value: boolean | undefined) => void;
  value: boolean | undefined;
};

const BulkButton : FunctionComponent<BulkButtonProps> = ({ children, onClick, value }) => {
  // @ts-ignore
  const icon = icons[value as any];
  // @ts-ignore
  const color = colors[value as any];

  return (
    <Button
      className={styles.bulkActionsButton}
      onClick={() => onClick(value)}
      size="sm"
      variant="outline-secondary"
    >
      {children}
      <span style={{ color }}>
        {icon}
      </span>
    </Button>
  );
};

export default BulkButton;
