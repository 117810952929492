/* eslint-disable max-len,react/jsx-one-expression-per-line */
import React, { FunctionComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styles from './WebsiteOverview.module.css';
import { Platform } from '../../../utils/platformTypes';
import { APP_NAME } from '../../../utils/constants';
import WebsiteLink from '../WebsiteLink/WebsiteLink';
import WebsiteSummaryTable from "../WebsiteSummaryTable/WebsiteSummaryTable";

type Props = {
  website: Platform;
};

function renderMarkdown(content: string) {
  return (
    <ReactMarkdown
      components={{
        p: React.Fragment,
      }}
    >
      {content}
    </ReactMarkdown>
  );
}

const WebsiteOverview: FunctionComponent<Props> = ({ website }) => {
  const { info } = website;

  return (
    <>
      {info && (
      <p>
        <WebsiteLink website={website} /> {renderMarkdown(info.summary)} On this page, you will find basic information about the {website.name} house sitting platform.
      </p>
      )}

      <WebsiteSummaryTable website={website} />

      {info && info.pros.length > 0 && (
        <div className={styles.prosCons}>
          <h3>Pros:</h3>
          <ul>
            {info.pros.map((x, number) => (
              <li key={number}>
                {renderMarkdown(x)}
              </li>
            ))}
          </ul>
        </div>
      )}

      {info && info.cons.length > 0 && (
        <div className={styles.prosCons}>
          <h3>Cons:</h3>
          <ul>
            {info.cons.map((x, number) => (
              <li key={number}>
                {renderMarkdown(x)}
              </li>
            ))}
          </ul>
        </div>
      )}

      <h3>Is {website.name} legit?</h3>

      <p>
        Yes, {website.name} is a legit website where real people create assignments for other people to take care of their house and pets. However, that does not mean that you are guaranteed to find a house to sit if you become a member of {website.name}. Before purchasing a membership, you should always check that there are enough sits available in the location where you want to travel. Moreover, some places (like Paris, France) are more popular than others, making it harder to secure a sit if there are tens of other sitters trying to do the same thing.
      </p>
    </>
  );
};

export default WebsiteOverview;
