import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Layout from '../../components/Layout/Layout';
import WideLayout from '../../components/WideLayout/WideLayout';
import { LocationData } from '../../components/Locations/locationTypes';
import { IS_PRE_RENDERING } from '../../utils/constants';
import { getLocation } from '../../components/Locations/locationUtils';
import LocationAssignments from '../../components/Locations/LocationAssignments/LocationAssignments';
import LocationPlatforms from '../../components/Locations/LocationPlatforms/LocationPlatforms';
import LocationSummary from '../../components/Locations/LocationSummary/LocationSummary';
import LocationChildren from '../../components/Locations/LocationChildren/LocationChildren';
import LocationDirectory from '../../components/Locations/LocationDirectory/LocationDirectory';
import useSimpleAssignments from '../../utils/hooks/useSimpleAssignments';

const Location: FunctionComponent = () => {
  const { continent, country } = useParams();
  const routerLocation = useLocation();
  const [locationData, setLocationData] = useState<LocationData | undefined>(getLocation(continent, country));
  const { assignments, loadAssignments } = useSimpleAssignments(true);

  useEffect(() => {
    setLocationData(getLocation(continent, country));
  }, [continent, country, routerLocation]);

  useEffect(() => {
    if (locationData === undefined || IS_PRE_RENDERING) {
      return;
    }

    loadAssignments({
      location: locationData.continentForFilter,
      country: locationData.country,
      resultsPerPage: '8',
    });
  }, [loadAssignments, locationData]);

  if (continent === undefined) {
    return <LocationDirectory />;
  }

  if (locationData === undefined) {
    return (
      <Layout>
        <WideLayout>
          <h1>Location not found</h1>
        </WideLayout>
      </Layout>
    );
  }

  const title = `House sitting in ${locationData.stats.name}`;

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <WideLayout>
        <h1>{title}</h1>
        <LocationSummary location={locationData} />
        <LocationPlatforms location={locationData} />
        <LocationAssignments assignments={assignments} location={locationData} />
        <LocationChildren location={locationData} />
      </WideLayout>
    </Layout>
  );
};

export default Location;
