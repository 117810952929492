import React, {
  FunctionComponent, useCallback, useRef, useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './FilterPopup.module.css';
import { useOutsideAlerter, useWindowWidth } from '../../../../../utils/hooks';
import FilterButton from '../../FilterButton/FilterButton';
import modalStyles from '../FilterSharedModal/FilterModal.module.css';
import Popup from '../../Popup/Popup';
import PopupFooter from '../../Popup/PopupFooter';

type OnShow = () => void;

type Props = {
  onSave?: () => void;
  onClear?: () => void;
  popupWidth: number;
  title: string;
  content?: React.ReactElement;
  control?: React.ReactElement;
  renderContent?: (isModal: boolean) => React.ReactElement;
  renderControl?: (isModal: boolean) => React.ReactElement;
  show: boolean;
  setShow: (show: boolean) => void;
};

const FilterPopup: FunctionComponent<Props> = ({
  onSave, onClear, popupWidth, title, content, control, show, setShow,
  renderContent, renderControl,
}) => {
  const wrapperRef = useRef(null);
  const windowWidth = useWindowWidth();
  const showModal = windowWidth < 992;
  const onOutsideClick = useCallback(() => {
    if (!showModal) {
      setShow(false);
    }
  }, [showModal]);
  useOutsideAlerter(wrapperRef, onOutsideClick);
  const onShow = useCallback(() => setShow(true), []);

  let controlComponent = control;
  if (controlComponent === undefined && renderControl !== undefined) {
    controlComponent = renderControl(showModal);
  }

  let contentComponent = content;
  if (contentComponent === undefined && renderContent !== undefined) {
    contentComponent = renderContent(showModal);
  }

  return (
    <div className={styles.container} ref={wrapperRef}>
      {controlComponent}
      {showModal ? (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={modalStyles.container}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.content}>
            {contentComponent}
          </Modal.Body>
          {(onSave !== undefined || onClear !== undefined)
          && (
          <Modal.Footer className={styles.footer}>
            {onClear !== undefined && <Button variant="secondary" onClick={onClear}>Reset</Button>}
            {onSave !== undefined && <Button onClick={onSave}>Apply</Button>}
          </Modal.Footer>
          )}
        </Modal>
      )
        : (
          <Popup show={show} style={{ width: popupWidth }}>
            <div className={styles.pickerContainer}>
              {contentComponent}
            </div>
            {(onSave !== undefined || onClear !== undefined) && <PopupFooter onClear={onClear} onApply={onSave} />}
          </Popup>
        )}
    </div>
  );
};

export default FilterPopup;
