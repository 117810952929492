import React, { FunctionComponent } from 'react';
import styles from './LocationPicker.module.css';

type Props = {
  text: string;
  type?: string;
  onClick: () => void;
};

const Location: FunctionComponent<Props> = ({ text, type, onClick }) => {
  if (type === undefined) {
    return (
      <div className={styles.location} onClick={onClick}>
        {text}
      </div>
    );
  }

  return (
    <div className={styles.location} onClick={onClick}>
      {text}
      <div className={styles.locationType}>
        {type}
      </div>
    </div>
  );
};

export default Location;
