import React, { FunctionComponent } from 'react';
import { Helmet } from 'react-helmet-async';
import styles from './NotFound.module.css';
import Layout from '../../components/Layout/Layout';
import TextContent from '../../components/TextContent/TextContent';

const NotFound: FunctionComponent = () => (
  <Layout>
    <Helmet>
      <title>404 - Page not found</title>
    </Helmet>
    <TextContent>
      <h1>Page not found</h1>
    </TextContent>
  </Layout>
);

export default NotFound;
