import React, { FunctionComponent, useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Button } from 'react-bootstrap';
import styles from './SavedSearches.module.css';
import { ApiResult } from '../../../utils/types';
import { SavedSearchReadDto } from '../../../myApi';
import { getApiInstance } from '../../../utils/api';
import {Link} from "react-router-dom";

const SavedSearches: FunctionComponent = () => {
  const [state, setState] = useState<ApiResult<SavedSearchReadDto[]>>({ data: null, isLoading: true });
  const [show, setShow] = useState(false);

  const fetch = async () => {
    const api = getApiInstance();
    const response = await api.api.savedSearchList();
    setState({
      data: response.data,
      isLoading: false,
    });
  };

  useEffect(() => {
    fetch().catch(console.log);
  }, []);

  const handleDelete = async (id: number) => {
    const api = getApiInstance();
    await api.api.savedSearchDelete({ id });
    await fetch();
  };

  // TODO: improve
  if (state.isLoading) {
    return null;
  }

  return (
    <div>
      <div onClick={() => setShow(!show)}>Saved searches (click to toggle)</div>
      {show && (
      <div>
        {state.data && state.data.map((x) => (
          <div key={x.id}>
            {x.name}
            {' '}
            <Link to={`/${x.urlData}`}>
              <Button variant="outline-secondary" size="sm">
                Show
              </Button>
            </Link>
            <Button variant="outline-danger" size="sm" onClick={() => handleDelete(x.id!)}>
              Delete
            </Button>
          </div>
        ))}
      </div>
      )}
    </div>
  );
};

export default SavedSearches;
