import React from 'react';
import { Link } from 'react-router-dom';
import { Platform, PlatformNoId, PlatformSlug } from './platformTypes';

export const platforms : Platform[] = [
  {
    id: 1,
    name: 'TrustedHousesitters',
    slug: 'trustedhousesitters',
    image: '/logos/trustedhousesitters.png',
    url: 'https://www.awin1.com/cread.php?awinmid=5759&awinaffid=1074833&ued=https%3A%2F%2Fwww.trustedhousesitters.com%2F',
    info: {
      price: ['$129-$249', 'per year', '(multiple tiers)'],
      url: 'trustedhousesitters.com',
      assignmentsCount: 9000,
      countries: 'International',
      mobileApp: 'Yes',
      features: 'Insurance, two-way reviews, ID checks',
      availableHere: true,
      pros: [
        'The largest house sitting and pet sitting website in the world',
        'Thousands of house sitting assignments all over the world',
        'It is possible to set up email alerts and mobile notifications for saved searches',
      ],
      cons: [
        'The highest membership fee of all the platforms',
        'Very competitive - you must react quickly to be able to secure a sit',
      ],
      summary: 'is the **largest house sitting website** with thousands of house sitting assignments all over the world. TrustedHousesitters is a safe bet if you want travel around the world while house sitting. Even though they are more expensive than other platforms, they offer some interesting benefits like an insurance for situations when your sit is cancelled. Just be prepared that the platform is quite competitive - you have to react quickly if you want to secure the more interesting sits.',
    },
  },
  {
    id: 2,
    name: 'MindMyHouse',
    slug: 'mindmyhouse',
    image: '/logos/mindmyhouse.png',
    url: 'https://www.mindmyhouse.com/sitters/overview',
    info: {
      price: ['$20', 'per year'],
      url: 'mindmyhouse.com',
      assignmentsCount: 300,
      countries: 'International',
      mobileApp: 'No',
      features: 'Reviews, home owners can join for free',
      availableHere: true,
      pros: [
        'Very cheap membership',
      ],
      cons: [
        'Smaller number of available assignments',
      ],
      summary: 'is a popular house sitting website with hundreds of house sitting assignments to choose from. While they are not the largest platforms when it comes to the number of available sitting assignments, they have one of the cheapest membership fees for just $20 per year.',
    },
  },
  {
    id: 3,
    name: 'MindAHome Australia',
    slug: 'mindahomeaustralia',
    image: '/logos/mindahomeaustralia.png',
    url: 'https://affiliates.mindahome.com.au/idevaffiliate.php?id=190',
    info: {
      price: ['$59', 'per year'],
      url: 'mindahome.com.au',
      assignmentsCount: 1000,
      countries: 'Australia',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: true,
      pros: [
        'Good if you are looking for a house sit in Australia',
        'Relatively cheap membership for house sitters',
      ],
      cons: [
        'Australian only',
      ],
      summary: 'is a popular house sitting website with hundreds of house sitting assignments all over Australia.',
    },
  },
  {
    id: 4,
    name: 'MindAHome UK',
    slug: 'mindahomeuk',
    image: '/logos/mindahomeuk.png',
    url: 'https://affiliates.mindahome.com.au/idevaffiliate.php?id=190&url=61',
    info: {
      price: ['£15', 'per year'],
      url: 'mindahome.co.uk',
      assignmentsCount: 200,
      countries: 'United Kingdom',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: true,
      pros: [
        'Relatively cheap membership for house sitters',
      ],
      cons: [
        'UK only',
      ],
      summary: 'is a house sitting platform that specializes on house sitting assignments in the United Kingdom. They offer a very cheap membership - for just £15 per year.',
    },
  },
  // { id: 5, name: 'HouseSittersUK', image: '/logos/housesittersuk.png' },
  // { id: 6, name: 'AussieHouseSitters', image: '/logos/aussiehousesitters.png' },
  // { id: 7, name: 'HouseSittersCanada', image: '/logos/housesitterscanada.png' },
  // { id: 8, name: 'HouseSittersAmerica', image: '/logos/housesittersamerica.png' },
  // { id: 9, name: 'KiwiHouseSitters', image: '/logos/kiwihousesitters.png' },
];

export const otherPlatforms : PlatformNoId[] = [
  {
    name: 'House Sitters UK',
    slug: 'housesittersuk',
    image: '/logos/housesittersuk.png',
    url: 'https://www.housesittersuk.co.uk?tap_a=47661-68e942&tap_s=2842842-2bbad2',
    info: {
      price: ['£29', 'per year'],
      url: 'housesittersuk.co.uk',
      assignmentsCount: 600,
      countries: 'United Kingdom',
      mobileApp: 'No',
      features: 'ID verification, reviews, home owners can join for free',
      availableHere: false,
      pros: [
        'Relatively cheap membership for house sitters',
      ],
      cons: [
        'UK only',
      ],
      summary: 'is a house sitting website with several hundreds of house sitting assignments all over the United Kingdom. They offer a relatively cheap membership. You can pay a fee to have your ID verified and then have a badge displayed on your profile.',
    },
  },
  {
    name: 'Aussie House Sitters',
    slug: 'aussiehousesitters',
    image: '/logos/aussiehousesitters.png',
    url: 'https://www.aussiehousesitters.com.au/?tap_a=29446-7ada98&tap_s=2842842-2bbad2',
    info: {
      price: ['$84', 'per year'],
      url: 'aussiehousesitters.com.au',
      assignmentsCount: 2000,
      countries: 'Australia',
      mobileApp: 'No',
      features: 'ID verification, reviews, home owners can join for free',
      availableHere: false,
      pros: [],
      cons: [
        'Australia only',
      ],
      summary: 'is a house sitting website with thousand of house sitting assignments all over Australia. You can pay a fee to have your ID verified and then have a badge displayed on your profile.',
    },
  },
  {
    name: 'House Sitters Canada',
    slug: 'housesitterscanada',
    image: '/logos/housesitterscanada.png',
    url: 'https://www.housesitterscanada.com?tap_a=50280-ebbaa4&tap_s=2842842-2bbad2',
    info: {
      price: ['$59', 'per year'],
      url: 'housesitterscanada.com',
      assignmentsCount: 100,
      countries: 'Canada',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: [],
      cons: [
        'Canada only',
      ],
      summary: 'is a house sitting website with around a hundred of house sitting assignments all over Canada. They are the only house sitting website dedicated to Canada.',
    },
  },
  {
    name: 'House Sitters America',
    slug: 'housesittersamerica',
    image: '/logos/housesittersamerica.png',
    url: 'https://www.housesittersamerica.com/?tap_a=77689-030356&tap_s=2842842-2bbad2',
    info: {
      price: ['$49', 'per year'],
      url: 'housesittersamerica.com',
      assignmentsCount: 200,
      countries: 'United States',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: [],
      cons: [
        'United States only',
      ],
      summary: 'is a house sitting website with hundreds of house sitting assignments all over the United States. They are the only house sitting website dedicated to the United States.',
    },
  },
  {
    name: 'Kiwi House Sitters',
    slug: 'kiwihousesitters',
    image: '/logos/kiwihousesitters.png',
    url: 'https://www.kiwihousesitters.co.nz/?tap_a=32975-4d69cb&tap_s=2842842-2bbad2',
    info: {
      price: ['$84', 'per year'],
      url: 'kiwihousesitters.co.nz',
      assignmentsCount: 500,
      countries: 'New Zealand',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: [],
      cons: [
        'New Zealand only',
      ],
      summary: 'is a house sitting website with hundreds of house sitting assignments all over New Zealand.',
    },
  },
  {
    name: 'House Carers',
    slug: 'housecarers',
    image: '/logos/housecarers.png',
    url: 'https://df8afdvqf2-llpe2rq25d-282p.hop.clickbank.net',
    info: {
      price: ['$50', 'per year'],
      url: 'housecarers.com',
      assignmentsCount: 150,
      countries: 'International',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: [],
      cons: [],
      summary: 'is a house sitting website with hundreds of house sitting assignments all over the world.',
    },
  },
  {
    name: 'Nomador',
    slug: 'nomador',
    image: '/logos/nomador.webp',
    url: 'https://www.nomador.com/',
    info: {
      price: ['$99', 'per year', '+ limited free tier'],
      url: 'nomador.com',
      assignmentsCount: 1500,
      countries: 'International',
      mobileApp: 'Yes',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: [],
      cons: ['parts of the website are in French'],
      summary: 'is a house sitting website with hundreds of house sitting assignments all over the world. The majority housesits are located in France but you can also find some international ones.',
    },
  },
  {
    name: 'Happy House Sitters',
    slug: 'happyhousesitters',
    image: '/logos/happyhousesitters.png',
    url: 'https://www.happyhousesitters.com.au/',
    info: {
      price: ['$69', 'per year', '+ 4 and 18 months options'],
      url: 'happyhousesitters.com.au',
      assignmentsCount: 500,
      countries: 'Australia',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: ['will give you some money back if you do not find any sit (conditions apply)'],
      cons: [],
      summary: 'is a house sitting website with hundreds of house sitting assignments all over Australia.',
    },
  },
  {
    name: 'The House Sitters',
    slug: 'thehousesitters',
    image: '/logos/thehousesitters.png',
    url: 'https://www.housesitters.com.au/',
    info: {
      price: ['$50', 'per year', '+ 2 years option'],
      url: 'housesitters.com.au',
      assignmentsCount: 400,
      countries: 'Australia',
      mobileApp: 'No',
      features: 'Home owners can join for free',
      availableHere: false,
      pros: [],
      cons: [],
      summary: 'is a house sitting website with hundreds of house sitting assignments all over Australia.',
    },
  },
];

export const allPlatforms : PlatformNoId[] = otherPlatforms.concat(platforms);

export function getPlatformById(id: number) : Platform | undefined {
  return platforms.find((x) => x.id === id);
}

export function getPlatformBySlugOrUndefined(slug: string) : Platform | undefined {
  return platforms.find((x) => x.slug === slug);
}

export function getPlatformBySlug(slug: PlatformSlug) {
  return allPlatforms.find((x) => x.slug === slug)!;
}

export function getPlatformUrl(slug: PlatformSlug) {
  return `/out/website/${slug}`;
}

export function getPlatformLink(slug: PlatformSlug) {
  const platform = allPlatforms.find((x) => x.slug === slug);

  if (platform === undefined) {
    throw new Error('Platform not found');
  }

  return <Link to={getPlatformUrl(slug)}>{platform.name}</Link>;
}
