import { PlatformId } from '../../../../../myApi';
import { PlatformsFilter } from '../../filterTypes';
import { platforms } from '../../../../../utils/platforms';

export type State = Partial<Record<PlatformId, boolean>>;
export const initialState : State = {};

export function preProcessState(filter: PlatformsFilter) : State {
  const state : State = {};

  if (filter.websites === undefined) {
    platforms.forEach((x) => {
      state[x.id] = true;
    });
  } else {
    filter.websites.forEach((x) => {
      state[x] = true;
    });
  }

  return state;
}

export function postProcessState(state: State) : PlatformsFilter {
  const newFilter : PlatformsFilter = { websites: [] };

  Object.entries(state).forEach(([key, value]) => {
    if (value) {
      newFilter.websites?.push(key as unknown as PlatformId);
    }
  });

  if (newFilter.websites?.length === platforms.length) {
    newFilter.websites = undefined;
  }

  return newFilter;
}
