import React, { FunctionComponent, useContext, useEffect } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useNavigate } from 'react-router-dom';
import styles from './Logout.module.css';
import Layout from '../../components/Layout/Layout';
import { AuthContext } from '../../utils/context/authContext';
import { API_INSTANCE } from '../../utils/api';

const Logout: FunctionComponent = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/');
    } else {
      const fetch = async () => {
        const response = await API_INSTANCE.api.authLogoutCreate();
        setIsLoggedIn(false);
      };
      fetch().catch(console.log);
    }
  });

  return (
    <Layout>
      loading...
    </Layout>
  );
};

export default Logout;
