import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { APP_URL } from '../../../utils/constants';

const CanonicalUrl: FunctionComponent = () => {
  const location = useLocation();

  let appUrl = APP_URL;
  if (appUrl.endsWith('/')) {
    appUrl = appUrl.substring(0, appUrl.length - 1);
  }

  const canonicalUrl = appUrl + location.pathname + location.search;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default CanonicalUrl;
