/* eslint-disable react/jsx-one-expression-per-line */
import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link, useLocation } from 'react-router-dom';
import styles from './LocationChildren.module.css';
import { LocationData } from '../locationTypes';
import { getChildrenName, getRoundedSitsCount } from '../locationUtils';

type Props = {
  location: LocationData;
};

const LocationChildren: FunctionComponent<Props> = ({ location }) => {
  const routerLocation = useLocation();

  if (location.stats.children === null || Object.keys(location.stats.children).length === 0) {
    return null;
  }

  const children = Object.entries(location.stats.children);
  children.sort((a, b) => a[1].name.localeCompare(b[1].name));

  return (
    <div>
      <h3>List of {getChildrenName(location.stats.type)[1].toLowerCase()}</h3>
      Below is a list of countries where we have seen house sitting assignments being published by home owners. For each {getChildrenName(location.stats.type)[0].toLowerCase()}, we show the number of sits that you can usually find there. Note that the data shown here are based on the house sitting platforms that we currently have in our database.

      <ul className={styles.childrenList}>
        {children.map((x) => (
          <li key={x[0]}>
            <Link to={`${routerLocation.pathname}${x[0]}/`}>
              {x[1].name}
            </Link>
            <span className={styles.sitsCount}>
              (usually {getRoundedSitsCount(x[1].count)} sits)
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationChildren;
