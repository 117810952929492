import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import styles from './TextContent.module.css';

const TextContent: FunctionComponent = ({ children }) => (
  <Container className={styles.container}>
    <div className={styles.content}>
      {children}
    </div>
  </Container>
);

export default TextContent;
