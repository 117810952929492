import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { allPlatforms } from '../../utils/platforms';
import NotFound from '../NotFound/NotFound';

const WebsiteOut: FunctionComponent = () => {
  const { websiteSlug } = useParams();
  const platform = allPlatforms.find((x) => x.slug === websiteSlug);

  if (platform === undefined) {
    return <NotFound />;
  }

  window.location.replace(platform.url);
  return null;
};

export default WebsiteOut;
