/* eslint-disable react/jsx-one-expression-per-line */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styles from './Website.module.css';
import Layout from '../../components/Layout/Layout';
import WideLayout from '../../components/WideLayout/WideLayout';
import { getWebsite } from '../../components/Websites/websiteUtils';
import WebsiteOverview from '../../components/Websites/WebsiteOverview/WebsiteOverview';
import WebsiteAssignments from '../../components/Websites/WebsiteAssignments/WebsiteAssignments';
import { Platform } from '../../utils/platformTypes';
import useSimpleAssignments from '../../utils/hooks/useSimpleAssignments';

const Website: FunctionComponent = () => {
  const { websiteSlug } = useParams();
  const [website, setWebsite] = useState<Platform | undefined>(getWebsite(websiteSlug));
  const { assignments, loadAssignments } = useSimpleAssignments(true);

  useEffect(() => {
    setWebsite(getWebsite(websiteSlug));
  }, [websiteSlug]);

  useEffect(() => {
    if (website === undefined) {
      return;
    }

    loadAssignments({ websites: [website.id], resultsPerPage: '8' });
  }, [loadAssignments, website]);

  if (website === undefined) {
    return (
      <Layout>
        <WideLayout>
          <h1>Website not found</h1>
        </WideLayout>
      </Layout>
    );
  }

  return (
    <Layout>
      <Helmet>
        <title>{website.name}</title>
      </Helmet>
      <WideLayout>
        <h1>
          {website.name}
          <img src={website.image} alt={`${website.name} logo`} className={styles.logo} />
        </h1>
        <WebsiteOverview website={website} />
        <WebsiteAssignments website={website} assignments={assignments} />
      </WideLayout>
    </Layout>
  );
};

export default Website;
