/* eslint-disable react/jsx-one-expression-per-line,max-len */
import React, { FunctionComponent } from 'react';
// @ts-ignore
import Admonition from 'react-admonitions';
import styles from './LocationSummary.module.css';
import { LocationData } from '../locationTypes';

type Props = {
  location: LocationData;
};

const LocationSummary: FunctionComponent<Props> = ({ location }) => (
  <div>
    This page contains information that we have gathered about <b>house sitting in {location.stats.name}</b> by scanning various house sitting website. You can find out which house sitting websites are the most popular in this region, or see some of the recent sitting assignments that are waiting for you to apply.

    {location.stats.count === 0 && (
    <>
      <br />
      <br />
      <Admonition type="note" title="Location not popular" iconType="emoji">
        It looks like this location is not very popular for house sitting right now. Or maybe we just do not have the right house sitting platforms in our database. Please check back later or choose a different location.
      </Admonition>
    </>
    )}
  </div>
);

export default LocationSummary;
