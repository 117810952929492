import React, {
  createRef,
  ForwardRefRenderFunction, FunctionComponent, useCallback, useImperativeHandle, useState,
} from 'react';
import { AssignmentFilter } from './filterTypes';

export type FilterSectionProps<Type = AssignmentFilter> = {
  values: Type;
  ref: any;
  insideModal: boolean;
};

// eslint-disable-next-line max-len
export interface FilterSectionComponent<Filter> extends ForwardRefRenderFunction<FilterSectionRef, FilterSectionProps<Filter>> {}

export type FilterSection<Type = AssignmentFilter> = {
  name: string;
  title: string;
  Component: FunctionComponent<FilterSectionProps<Type>>;
  popupWidth: number;
  extractState: (values: Type) => Type;
  shortDescription?: (values: Type) => React.ReactElement | null | string;
};

export type FilterSectionRef<Type = AssignmentFilter> = {
  getValues: () => Type | false;
};

export function useFilterSection<Filter>(
  ref: React.Ref<FilterSectionRef<Filter>>,
  init: () => FilterSectionRef<Filter>,
) {
  useImperativeHandle<FilterSectionRef<Filter>, FilterSectionRef<Filter>>(ref, init);
}

type SectionElement = {
  ref: React.RefObject<FilterSectionRef>,
  sectionRef: React.RefObject<HTMLDivElement>,
};

type SectionElements = { [key : string]: SectionElement };
type SectionValues = { [key : string]: AssignmentFilter | false };

type UseFilterSectionsReturn = {
  getValues: () => SectionValues;
  register: (name: string) => SectionElement;
};

export function useFilterSections() : UseFilterSectionsReturn {
  const [elements, setElements] = useState<SectionElements>({});

  // Get values of individual sections
  const getValues = useCallback(() => {
    const values : SectionValues = {};
    let scrolledToError = false;
    Object.keys(elements).forEach((name) => {
      const element = elements[name];
      const sectionValues = element.ref.current?.getValues();

      if (sectionValues === false && !scrolledToError) {
        element.sectionRef.current?.scrollIntoView();
        scrolledToError = true;
      }

      if (sectionValues !== undefined) {
        values[name] = sectionValues;
      }
    });

    return values;
  }, [elements]);

  // Register filter section
  const register = useCallback((name: string) => {
    let element = elements[name];

    // Create a new ref if it does not exist
    if (element === undefined) {
      element = {
        ref: createRef<FilterSectionRef>(),
        sectionRef: createRef(),
      };

      setElements((prev) => ({
        ...prev,
        [name]: element,
      }));
    }

    return {
      ref: element.ref,
      sectionRef: element.sectionRef,
    };
  }, [elements]);

  return {
    register,
    getValues,
  };
}
