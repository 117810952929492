import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styles from './WebsiteAssignments.module.css';
import { Platform } from '../../../utils/platformTypes';
import ListView from '../../Assignments/ListView/ListView';
import { ApiResult, PaginatedAssignments } from '../../../utils/types';

type Props = {
  assignments: ApiResult<PaginatedAssignments>;
  website: Platform;
};

const WebsiteAssignments: FunctionComponent<Props> = ({ website, assignments }) => (
  <div>
    <h3>
      Latest sits from
      {' '}
      {website.name}
    </h3>
    {(assignments.isLoading || assignments.data === null || assignments.data!.totalResults > 0) ? (
      <>
        {' '}
        <ListView
          assignments={assignments}
          onPageChanged={() => {
          }}
          currentPage={1}
          withPagination={false}
          withHeader={false}
          useAlternativeSizing
        />
        <div className={styles.sitsButtonContainer}>
          <Link to={`/?platforms[0]=${website.id}`}>
            <Button variant="primary">
              Browse all sits from
              {' '}
              {website.name}
            </Button>
          </Link>
        </div>
      </>
    ) : (
      <>
        It looks like there are currently no house sits in this area.
        {' '}
        <Link to="/" className={styles.browseAll}>
          <Button variant="primary">
            Browse all international sits
          </Button>
        </Link>
      </>
    )}
  </div>
);

export default WebsiteAssignments;
