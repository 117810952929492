/* eslint-disable react/jsx-one-expression-per-line */
import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import styles from './WebsiteSummaryTable.module.css';
import { APP_NAME } from '../../../utils/constants';
import { PlatformNoId } from '../../../utils/platformTypes';

type Props = {
  website: PlatformNoId;
};

function renderMarkdown(content: string) {
  return (
    <ReactMarkdown
      components={{
        p: React.Fragment,
      }}
    >
      {content}
    </ReactMarkdown>
  );
}

const WebsiteSummaryTable: FunctionComponent<Props> = ({ website }) => {
  const { info } = website;

  return (
    <div>
      {info && (
        <div className={styles.infoTableWrapper}>
          <table className={styles.infoTable}>
            <tbody>
              <tr>
                <td>Website:</td>
                <td><Link to={`/out/website/${website.slug}`}>{renderMarkdown(info.url)}</Link></td>
              </tr>
              <tr>
                <td>Price:</td>
                <td>
                  <b>{info.price[0]}</b>
                  {info.price.map((x, index) => {
                    if (index === 0) {
                      return null;
                    }

                    return (
                      <span key={x}>
                        {' '}{x}
                      </span>
                    );
                  })}
                </td>
              </tr>
              <tr>
                <td>Number of assignments:</td>
                <td>Usually {info.assignmentsCount}+</td>
              </tr>
              <tr>
                <td>Sit locations:</td>
                <td>{info.countries}</td>
              </tr>
              <tr>
                <td>Mobile app:</td>
                <td>{info.mobileApp}</td>
              </tr>
              <tr>
                <td>Other features:</td>
                <td>{info.features}</td>
              </tr>
              {info.availableHere && (
              <tr>
                <td>Available on {APP_NAME}:</td>
                <td>Yes, find all {website.name} assignments <Link to={`/?platforms[0]=${website.id}`}>here</Link></td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default WebsiteSummaryTable;
