import React, { FunctionComponent } from 'react';
import { Pagination as PaginationComp } from 'react-bootstrap';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from './Pagination.module.css';
import { RESULTS_PER_PAGE } from '../../../utils/constants';

type PaginationItemProps = {
  number: number;
  onChange: (page: number) => void;
  isActive?: boolean;
};

const PaginationItem: FunctionComponent<PaginationItemProps> = ({ number, onChange, isActive }) => (
  <PaginationComp.Item
    key={number}
    onClick={() => onChange(number)}
    active={isActive}
  >
    {number}
  </PaginationComp.Item>
);

PaginationItem.defaultProps = {
  isActive: false,
};

type Props = {
  currentPage: number;
  totalPages: number;
  onChange: (page: number) => void;
};

const Pagination: FunctionComponent<Props> = ({ currentPage, totalPages, onChange }) => {
  const items = [];

  const maxSmallerItems = currentPage - 1;
  const maxLargerItems = totalPages - currentPage;
  const itemsOnEachSide = 3;

  let smallerItems = Math.min(maxSmallerItems, itemsOnEachSide);
  let largerItems = Math.min(maxLargerItems, itemsOnEachSide);
  const leftoverItems = itemsOnEachSide * 2 - smallerItems - largerItems;
  smallerItems = Math.min(maxSmallerItems, itemsOnEachSide + leftoverItems);
  largerItems = Math.min(maxLargerItems, itemsOnEachSide + leftoverItems);

  const ellipsisOnTheLeft = maxSmallerItems - smallerItems > 0;
  const ellipsisOnTheRight = maxLargerItems - largerItems > 0;

  for (let number = 0; number < smallerItems; number += 1) {
    if (number === 0 && ellipsisOnTheLeft) {
      items.push(<PaginationItem key="first" number={1} onChange={onChange} />);
    } else if (number === 1 && ellipsisOnTheLeft) {
      items.push(<PaginationComp.Ellipsis key="ellipsis_left" disabled />);
    } else {
      const pageNumber = currentPage - smallerItems + number;
      items.push(<PaginationItem key={pageNumber} number={pageNumber} onChange={onChange} />);
    }
  }

  items.push(<PaginationItem key={currentPage} number={currentPage} onChange={onChange} isActive />);

  for (let number = largerItems - 1; number >= 0; number -= 1) {
    if (number === 0 && ellipsisOnTheRight) {
      items.push(<PaginationItem key="last" number={totalPages} onChange={onChange} />);
    } else if (number === 1 && ellipsisOnTheRight) {
      items.push(<PaginationComp.Ellipsis key="ellipsis_right" disabled />);
    } else {
      const pageNumber = currentPage + largerItems - number;
      items.push(<PaginationItem key={pageNumber} number={pageNumber} onChange={onChange} />);
    }
  }

  return (
    <div>
      <PaginationComp className={styles.container}>
        <PaginationComp.Prev
          disabled={currentPage === 1}
          onClick={() => onChange(currentPage - 1)}
        />
        {items}
        <PaginationComp.Next
          disabled={currentPage === totalPages}
          onClick={() => onChange(currentPage + 1)}
        />
      </PaginationComp>
    </div>
  );
};

export default Pagination;
