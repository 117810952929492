import React, { FunctionComponent, lazy, Suspense } from 'react';
import {
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import styles from './Home.module.css';
import Layout from '../../components/Layout/Layout';
import useAssignments from '../../utils/hooks/useAssignments';
import ListView from '../../components/Assignments/ListView/ListView';
import NewFilter from '../../components/Assignments/Filter/NewFilter';

const MapView = lazy(() => import('../../components/Assignments/MapView/MapView'));

const Home: FunctionComponent = () => {
  const {
    assignments,
    currentPage, handleChangePage,
    handleFilterChange, state,
    geoClusters, handleMapChange, mapBounds,
  } = useAssignments();

  return (
    <Layout>
      <NewFilter onChange={handleFilterChange} values={state.filter} />

      <Container fluid className="g-0">
        <Row style={{ position: 'relative' }} className="g-0 flex-column-reverse flex-lg-row">
          <Col className={`${styles.list} primary-padding`} xs={12} lg={7}>
            <ListView
              assignments={assignments}
              onPageChanged={handleChangePage}
              currentPage={currentPage}
            />
          </Col>
          <Col className={styles.map} xs={12} lg={5}>
            <Suspense fallback={(
              <Spinner animation="border" role="status" size="sm">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              )}
            >
              <MapView
                clusters={geoClusters}
                onMove={handleMapChange}
                bounds={mapBounds}
              />
            </Suspense>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Home;
