import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, Location } from 'react-router-dom';

const ScrollToTop: FunctionComponent = () => {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<Location>(location);

  useEffect(() => {
    // Do not scroll if the pathname did not change
    // (e.g. when a search is updated)
    if (location.pathname !== previousLocation.pathname) {
      document.documentElement.style.scrollBehavior = 'auto';
      setTimeout(() => window.scrollTo(0, 0), 5);
      setTimeout(() => {
        document.documentElement.style.scrollBehavior = 'smooth';
      }, 5);
    }

    setPreviousLocation(location);
  }, [location, previousLocation]);

  return null;
};

export default ScrollToTop;
