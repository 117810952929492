import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from 'react-bootstrap';
import Home from './pages/Home/Home';
import Terms from './pages/Terms/Terms';
import NotFound from './pages/NotFound/NotFound';
import Privacy from './pages/Privacy/Privacy';
import ScrollToTop from './components/Utils/ScrollToTop/ScrollToTop';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import About from './pages/About/About';
import { useResizeWindow } from './utils/hooks';
import useAnalytics from './utils/hooks/useAnalytics';
import { IS_PRODUCTION } from './utils/constants';
import CanonicalUrl from './components/Utils/CanonicalUrl/CanonicalUrl';
import Location from './pages/Location/Location';
import Website from './pages/Website/Website';
import Websites from './pages/Websites/Websites';
import Login from './pages/Login/Login';
import AuthProvider from './components/AuthProvider/AuthProvider';
import Logout from './pages/Logout/Logout';
import WebsitesComparison from './pages/WebsitesComparison/WebsitesComparison';
import WebsiteOut from "./pages/Out/WebsiteOut";

function App() {
  useResizeWindow();
  useAnalytics();

  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
    >
      <AuthProvider>
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | HouseSit Scanner"
            defaultTitle="Thousands of housesits at one place | HouseSit Scanner"
          >
            <meta name="description" content="Find your next house sitting adventure with our powerful filters and thousands of assignments from different websites." />
            {!IS_PRODUCTION && <meta name="robots" content="noindex" />}
          </Helmet>
          <ScrollToTop />
          <CanonicalUrl />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="locations" element={<Locations />} /> */}
            <Route path="terms/" element={<Terms />} />
            <Route path="privacy/" element={<Privacy />} />
            <Route path="how-it-works/" element={<HowItWorks />} />
            <Route path="about/" element={<About />} />
            <Route path="locations/" element={<Location />} />
            <Route path="locations/:continent/" element={<Location />} />
            <Route path="locations/:continent/:country/" element={<Location />} />
            <Route path="websites-comparison/" element={<WebsitesComparison />} />
            <Route path="websites/" element={<Websites />} />
            <Route path="websites/:websiteSlug/" element={<Website />} />
            <Route path="out/website/:websiteSlug" element={<WebsiteOut />} />
            <Route path="login" element={<Login />} />
            <Route path="logout" element={<Logout />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </HelmetProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
