/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Helmet } from 'react-helmet-async';
import styles from './About.module.css';
import Layout from '../../components/Layout/Layout';
import TextContent from '../../components/TextContent/TextContent';

const About: FunctionComponent = () => (
  <Layout>
    <Helmet>
      <title>About us</title>
    </Helmet>
    <TextContent>
      <h1>About us</h1>
      <p>
        Hello, my name is <b>Ondřej Nepožitek</b> and I'm a software developer from the Czech Republic. I'm also a petsitting enthusiast as I think that it's a great opportunity to meet new people & pets and also to travel on a budget.
      </p>

      <p>
        The reason why I created this platform is that I was not satisfied with the overall experience of searching for the next sit. The petsitting websites are sometimes not very responsive and user-friendly, or they lack a filter that I'd like to use. Therefore, I decided to write my own platform that would aggregate housesits from different platforms and provide a modern and responsive UI.
      </p>
    </TextContent>
  </Layout>
);

export default About;
