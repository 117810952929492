import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Container } from 'react-bootstrap';
import styles from './WideLayout.module.css';

const WideLayout: FunctionComponent = ({ children }) => (
  <Container className={styles.container}>
    <div className={styles.content}>
      {children}
    </div>
  </Container>
);

export default WideLayout;
