import qs from 'query-string';
import { Api, QueryParamsType } from '../myApi';
import { API_URL, QUERY_STRING_OPTIONS } from './constants';

export function getApiInstance() {
  const api = new Api({
    baseUrl: API_URL,
  });

  // @ts-ignore
  api.baseApiParams.credentials = 'include';

  // @ts-ignore
  api.toQueryString = (rawQuery?: QueryParamsType) => qs.stringify(rawQuery!, QUERY_STRING_OPTIONS);

  return api;
}

export const API_INSTANCE = getApiInstance();
