import { StringifyOptions } from 'query-string';

export const API_URL = process.env.REACT_APP_API_ENDPOINT;
export const ENABLE_GOOGLE_ANALYTICS = process.env.REACT_APP_ENABLE_GOOGLE_ANALYTICS === 'true';
export const IS_PRODUCTION = process.env.REACT_APP_IS_PRODUCTION === 'true';
export const DOMAIN = 'housesitscanner.com';
export const APP_NAME = 'HouseSit Scanner';
export const APP_URL = 'https://housesitscanner.com/';
export const RESULTS_PER_PAGE = 12;
export const QUERY_STRING_OPTIONS : StringifyOptions = { arrayFormat: 'index' };
export const IS_PRE_RENDERING = navigator.userAgent === 'ReactSnap';
