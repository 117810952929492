import { FaCheck, FaCheckDouble, FaTimes } from 'react-icons/fa';
import React from 'react';

export const colors = {
  true: '#28a745',
  false: '#dc3545',
  undefined: '#ffc107',
};

export const borderColors = {
  true: colors.true,
  false: colors.false,
  undefined: 'var(--bs-gray-300)',
};

export const icons = {
  true: <FaCheckDouble />,
  false: <FaTimes />,
  undefined: <FaCheck />,
};
