import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FaCheck, FaCheckDouble, FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'react-bootstrap';
import styles from './Responsibility.module.css';
import { borderColors, colors, icons } from '../constants';
import { Responsibility } from '../../../../../../utils/responsibilities';

type IconProps = {
  value: boolean | undefined;
  isActive: boolean;
  onClick: () => void;
};

const Icon : FunctionComponent<IconProps> = ({ value, isActive, onClick }) => {
  // @ts-ignore
  const color : string = colors[value];
  // @ts-ignore
  const icon : any = icons[value];
  const opacity = isActive ? 1 : 0.2;

  return (
    <span
      className={styles.icon}
      style={{ color, opacity }}
      onClick={() => onClick()}
    >
      {icon}
    </span>
  );
};

const nextValue = {
  true: false,
  false: undefined,
  undefined: true,
};

type Props = {
  type: Responsibility;
  value: boolean | undefined;
  onChange: (value: boolean | undefined) => void;
};

const ResponsibilityItem: FunctionComponent<Props> = ({ type, value, onChange }) => {
  // @ts-ignore
  const borderColor : string = borderColors[value];
  const boxShadow = value !== undefined ? `0 0 0 1px ${borderColor}` : '0 0 0 1px transparent';

  return (
    <Col
      xs={12}
      lg={6}
    >
      <div
        className={styles.container}
        style={{ borderColor, boxShadow }}
        onClick={() => {
        // @ts-ignore
          onChange(nextValue[value as any]);
        }}
      >
        <div>
          <FontAwesomeIcon className={styles.animalIcon} icon={type.icon} />
          <span>{type.name}</span>
        </div>
        <div>
          <Icon
            onClick={() => {}}
            value={value}
            isActive
          />
        </div>
      </div>
    </Col>
  );
};

export default ResponsibilityItem;
