import React, {
  FunctionComponent, useCallback, useEffect, useRef, useState,
} from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './FilterButtonWithPopup.module.css';
import modalStyles from '../FilterSharedModal/FilterModal.module.css';
import { AssignmentFilter } from '../../filterTypes';
import FilterButton from '../../FilterButton/FilterButton';
import Popup from '../../Popup/Popup';
import PopupFooter from '../../Popup/PopupFooter';
import { useOutsideAlerter, useWindowWidth } from '../../../../../utils/hooks';
import { FilterSection, FilterSectionRef } from '../../useFilter';

type Props = {
  values: AssignmentFilter;
  onChange: (values: AssignmentFilter) => void;
  section: FilterSection;
};

const FilterButtonWithPopup: FunctionComponent<Props> = ({ values, onChange, section }) => {
  const [show, setShow] = useState(false);
  const wrapperRef = useRef(null);
  const windowWidth = useWindowWidth();
  const showModal = windowWidth < 992;
  const onOutsideClick = useCallback(() => {
    if (!showModal) {
      setShow(false);
    }
  }, [showModal]);
  useOutsideAlerter(wrapperRef, onOutsideClick);
  const [currentValues, setCurrentValues] = useState<AssignmentFilter>(values);
  const sectionRef = useRef<FilterSectionRef | null>(null);

  // Update currentValues when values change
  useEffect(() => {
    setCurrentValues(values);
  }, [values]);

  const handleShow = () => {
    setShow(true);
  };

  const handleSave = () => {
    const newValues = sectionRef.current!.getValues();

    if (newValues === false) {
      return;
    }

    const extractedValues = section.extractState(newValues);
    onChange(extractedValues);
    setShow(false);
  };

  const handleClear = () => {
    setCurrentValues({});
  };

  const description = section.shortDescription !== undefined && section?.shortDescription(values);

  return (
    <div className={styles.container} ref={wrapperRef}>
      <FilterButton onClick={handleShow} isActive={description !== false && description !== null}>
        {description || section.title}
      </FilterButton>

      {showModal ? (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={modalStyles.container}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {section.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.content}>
            <section.Component
              values={currentValues}
              ref={sectionRef}
              insideModal={showModal}
            />
          </Modal.Body>
          <Modal.Footer className={styles.footer}>
            <Button variant="secondary" onClick={handleClear}>Reset</Button>
            <Button onClick={handleSave}>Apply</Button>
          </Modal.Footer>
        </Modal>
      )
        : (
          <Popup show={show} style={{ width: section.popupWidth }}>
            <div className={styles.pickerContainer}>
              <section.Component
                values={currentValues}
                ref={sectionRef}
                insideModal={showModal}
              />
            </div>
            <PopupFooter onClear={handleClear} onApply={handleSave} />
          </Popup>
        )}
    </div>
  );
};

export default FilterButtonWithPopup;
