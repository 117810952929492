/* eslint-disable react/jsx-one-expression-per-line,max-len */
import React, { FunctionComponent } from 'react';
import styles from './LocationPlatforms.module.css';
import { LocationData } from '../locationTypes';
import { getPlatformById } from '../../../utils/platforms';
import { getRoundedSitsCount } from '../locationUtils';

type Props = {
  location: LocationData;
};

const LocationPlatforms: FunctionComponent<Props> = ({ location }) => {
  if (location.stats.countByPlatform.length === 0) {
    return null;
  }

  return (
    <>
      <h3>Most popular house sitting websites</h3>
      Below is a list of the most popular house sitting and pet sitting websites in {location.stats.name}. The
      websites are ordered by the number of house sitting assignments that they usually have in the region.
      <ul className={styles.platformsList}>
        {location.stats.countByPlatform.map((x) => {
          const platform = getPlatformById(x.platform);

          if (platform === undefined) {
            return null;
          }

          return (
            <li key={x.platform}>
              {platform.image && (
                <div className={styles.platformLogoWrapper}>
                  <img alt={`${platform.name} logo`} className={styles.platformLogo} src={platform.image} />
                </div>
              )}
              <a href={platform.url} target="_blank" rel="noreferrer">
                {platform.name}
              </a>
              <span className={styles.sitsCount}>
                (usually {getRoundedSitsCount(x.count)} sits)
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default LocationPlatforms;
