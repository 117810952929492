import React, { FunctionComponent } from 'react';
import { Col } from 'react-bootstrap';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { AssignmentReadDto } from '../../../myApi';
import styles from './Card.module.css';
import Responsibilities from './Responsibilities';
import { platforms } from '../../../utils/platforms';
import Badges from "./Badges";

const timeFormatter : TimeAgo.Formatter = (value, unit, suffix, _, nextFormatter) => {
  if (unit === 'second' || (unit === 'minute' && value <= 30)) {
    return '<30 min ago';
  }

  if (unit === 'minute') {
    return '<1 hour ago';
  }

  if (nextFormatter !== undefined) {
    return nextFormatter(value, unit, suffix, _);
  }

  return null;
};

const Card : FunctionComponent<{ assignment: AssignmentReadDto }> = ({ assignment }) => {
  const platform = platforms.find((x) => x.id === assignment.platform);

  return (
    <Col>
      <a className={styles.container} href={assignment.url || undefined} target="_blank" rel="noreferrer">
        <div className={styles.image} style={{ backgroundImage: `url("${assignment.imageUrl}")` }}>
          <Badges assignment={assignment} />
        </div>
        <h3 className={styles.title}>{assignment.title}</h3>
        <div className={styles.dates}>
          {assignment.startDate && assignment.endDate
            ? (
              <>
                {moment(assignment.startDate).format('DD MMM YYYY')}
                {' '}
                -
                {' '}
                {moment(assignment.endDate).format('DD MMM YYYY')}
              </>
            )
            : 'Dates not filled in'}
        </div>
        <div className={styles.location}>
          {assignment.location}
        </div>
        <footer className={styles.footer}>
          <div>
            <Responsibilities assignment={assignment} />
          </div>
          <div>
            {assignment.foundAt && (
            <TimeAgo
              date={assignment.foundAt}
              title={`Found on ${moment(assignment.foundAt).format('DD MMM YYYY, HH:MM')}`}
              className={styles.foundOn}
              formatter={timeFormatter}
            />
            )}

            {platform && platform.image && (
              <img
                className={styles.logo}
                alt={platform.name}
                src={platform.image}
                title={platform.name}
              />
            )}
          </div>
        </footer>
      </a>
    </Col>
  );
};

export default Card;
