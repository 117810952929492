import { useCallback, useState } from 'react';
import qs from 'query-string';
import { ApiResult, AssignmentsQuery, PaginatedAssignments } from '../types';
import { Api, QueryParamsType } from '../../myApi';
import { API_URL, IS_PRE_RENDERING, QUERY_STRING_OPTIONS } from '../constants';

type Return = {
  assignments: ApiResult<PaginatedAssignments>;
  loadAssignments: (query: AssignmentsQuery) => void;
};

function useSimpleAssignments(isLoading: boolean) : Return {
  const [assignments, setAssignments] = useState<ApiResult<PaginatedAssignments>>({ data: null, isLoading });

  const loadAssignments = useCallback((query: AssignmentsQuery) => {
    if (IS_PRE_RENDERING) {
      return;
    }

    const fetchData = async () => {
      setAssignments((prev) => ({ ...prev, isLoading: true }));
      const api = new Api({
        baseUrl: API_URL,
      });
      // @ts-ignore
      api.toQueryString = (rawQuery?: QueryParamsType) => qs.stringify(rawQuery!, QUERY_STRING_OPTIONS);

      const response = await api.api.assignmentList(query as any);
      setAssignments({ isLoading: false, data: response.data as PaginatedAssignments });
    };

    fetchData().catch(console.error);
  }, []);

  return {
    assignments,
    loadAssignments,
  };
}

export default useSimpleAssignments;
