import React, { FunctionComponent } from 'react';
import styles from './WebsiteLink.module.css';
import { Platform } from '../../../utils/platformTypes';

type Props = {
  website: Platform;
};

const WebsiteLink: FunctionComponent<Props> = ({ website }) => (
  <a href={website.url} target="_blank">
    {website.name}
  </a>
);

export default WebsiteLink;
