/* eslint-disable react/jsx-one-expression-per-line,react/no-unescaped-entities,max-len */
import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './WebsitesComparison.module.css';
import WideLayout from '../../components/WideLayout/WideLayout';
import Layout from '../../components/Layout/Layout';
import {
  allPlatforms, getPlatformBySlug, getPlatformLink, getPlatformUrl,
} from '../../utils/platforms';
import { PlatformNoId } from '../../utils/platformTypes';
import WebsiteComparisonDetail from '../../components/Websites/WebsiteComparisonDetail/WebsiteComparisonDetail';

function renderMarkdown(content: string) {
  return (
    <ReactMarkdown
      components={{
        p: React.Fragment,
      }}
    >
      {content}
    </ReactMarkdown>
  );
}

function sort(a: PlatformNoId, b: PlatformNoId) {
  if (a.info?.countries === b.info?.countries) {
    return b.info!.assignmentsCount - a.info!.assignmentsCount;
  }

  return a.info!.countries.localeCompare(b.info!.countries);
}

const WebsitesComparison: FunctionComponent = () => {
  const internationalValue = 'International';
  const internationalPlatforms = allPlatforms.filter((x) => x.info?.countries === internationalValue);
  const regionalPlatforms = allPlatforms.filter((x) => x.info?.countries !== internationalValue);

  internationalPlatforms.sort(sort);
  regionalPlatforms.sort(sort);

  const sortedPlatforms = internationalPlatforms.concat(regionalPlatforms);
  const title = `Top ${sortedPlatforms.length} house sitting websites (2022)`;

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <WideLayout>
        <h1>{title}</h1>
        <p>
          Do you want to try house sitting or pet sitting but don't know which website to join? Then continue reading because we have a <b>comprehensive review of all the popular house sitting platforms</b>. All the information on this page were updated in May 2022.
        </p>

        <h2>House sitting websites overview</h2>

        <p>
          First, let's see an <b>overview of all the popular house sitting websites</b> that we that we have in our comparison. There are more house sitting websites out there but we decided to include only the ones that have at least 20 assignments listed on their site. The following table groups websites by the country in which they operate.
        </p>
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Website</th>
                <th className="text-center">Price</th>
                <th className="text-center">Country</th>
                <th className="text-center"># of sits</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sortedPlatforms.map((x) => {
                if (x.info === undefined) {
                  return null;
                }

                return (
                  <tr key={x.name}>
                    <td className={styles.title}>
                      <span className={styles.titleLogo}>
                        <img src={x.image} alt={`${x.name} logo`} />
                      </span>
                      {x.name}
                    </td>
                    {/* <td>{renderMarkdown(x.info.price)}</td> */}
                    <td className={styles.cellPrice}>
                      <b>{x.info.price[0]}</b>
                      {x.info.price.map((y, index) => {
                        if (index === 0) {
                          return null;
                        }

                        return (
                          <div className={styles.smaller} key={y}>
                            {y}
                          </div>
                        );
                      })}
                    </td>
                    <td className="text-center">{renderMarkdown(x.info.countries)}</td>
                    <td className="text-center">
                      {x.info.assignmentsCount}
                      +
                    </td>
                    <td className="text-center">
                      <Link to={getPlatformUrl(x.slug)} target="_blank" rel="noreferrer">
                        <Button variant="warning" size="sm">Go to website</Button>
                      </Link>
                      <div className={styles.readReview}>
                        <a href={`#${x.slug}`}>Read review</a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        {/* <h2>How to choose the right house sitting website</h2> */}

        {/* <h4>Price</h4> */}
        {/* <h4>Number of available assignments</h4> */}
        {/* <h4>Regional vs international</h4> */}

        <h2>Best international house sitting websites</h2>
        <p>
          When it comes to international house sitting websites, {getPlatformLink('trustedhousesitters')} is the clear winner here with more than 9000 assignments available all over the world. However, if you are on a budget, you can try {getPlatformLink('mindmyhouse')} which has much smaller membership fees.
        </p>
        <p>
          Continue reading below to find more about individual websites.
        </p>
        {sortedPlatforms.filter((x) => x.info!.countries === 'International').map((x, index) => (
          <WebsiteComparisonDetail website={x} order={index + 1} key={x.slug} />
        ))}

        <h2>Australia - best house sitting websites</h2>
        <p>
          Australia is a country with the highest number of regional house sitting and pet sitting platforms. You can also use {getPlatformLink('trustedhousesitters')} if you are already a member, but they have less assignments available in Australia than {getPlatformLink('aussiehousesitters')} and {getPlatformLink('mindahomeaustralia')}. If you want to spend some time house sitting in Australia, it might be a good idea to try one of the regional websites.
        </p>
        {sortedPlatforms.filter((x) => x.info!.countries === 'Australia').map((x, index) => (
          <WebsiteComparisonDetail website={x} order={index + 1} key={x.slug} />
        ))}

        <h2>New Zealand - best house sitting websites</h2>
        <p>
          If you want to house sit in New Zealand, {getPlatformLink('kiwihousesitters')} is the clear winner there with more than 500 assignments. You can also try {getPlatformLink('trustedhousesitters')} if you are already a member, but they have less than 100 sits in this region.
        </p>
        {sortedPlatforms.filter((x) => x.info!.countries === 'New Zealand').map((x, index) => (
          <WebsiteComparisonDetail website={x} order={index + 1} key={x.slug} />
        ))}

        <h2>United Kingdom - best house sitting websites</h2>
        <p>
          In the United Kingdom, you can try one of the two regional websites or just go with {getPlatformLink('trustedhousesitters')} as they have more than 5000 available assignments there.
        </p>
        {sortedPlatforms.filter((x) => x.info!.countries === 'United Kingdom').map((x, index) => (
          <WebsiteComparisonDetail website={x} order={index + 1} key={x.slug} />
        ))}

        <h2>United States - best house sitting websites</h2>
        <p>
          If you want to house sit in the United States, you can either join the regional {getPlatformLink('housesittersamerica')} platform or use the international {getPlatformLink('trustedhousesitters')} platform. Nowadays, {getPlatformBySlug('trustedhousesitters').name} have much more assignments available in the region, usually more than 4000, compared to around 200 assignments on {getPlatformBySlug('housesittersamerica').name}.
        </p>
        {sortedPlatforms.filter((x) => x.info!.countries === 'United States').map((x, index) => (
          <WebsiteComparisonDetail website={x} order={index + 1} key={x.slug} />
        ))}

        <h2>Canada - best house sitting websites</h2>
        <p>
          If you want to house sit in Canada, you can either join the regional {getPlatformLink('housesitterscanada')} platform or use the international {getPlatformLink('trustedhousesitters')} platform. {getPlatformBySlug('trustedhousesitters').name} usually have slightly more assignments available in the region, but also the membership fee is more than 2 times higher.
        </p>
        {sortedPlatforms.filter((x) => x.info!.countries === 'Canada').map((x, index) => (
          <WebsiteComparisonDetail website={x} order={index + 1} key={x.slug} />
        ))}
      </WideLayout>
    </Layout>
  );
};

export default WebsitesComparison;
