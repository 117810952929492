import React, {
  FunctionComponent, useContext, useEffect, useState,
} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import styles from './Filter.module.css';
import { AssignmentFilter } from './filterTypes';
import FilterModal from './ANewControls/FilterSharedModal/FilterModal';
import PlatformsPicker from './ANewControls/PlatformsPicker/PlatformsPicker';
import ResponsibilitiesPicker from './ANewControls/ResponsibilitiesPicker/ResponsibilitiesPicker';
import FilterButtonWithPopup from './ANewControls/FilterButtonWithPopup/FilterButtonWithPopup';
import { FilterSection } from './useFilter';
import DatePicker from './ANewControls/DatePicker/DatePicker';
import DurationPicker from './ANewControls/DurationPicker/DurationPicker';
import { useWindowWidth } from '../../../utils/hooks';
import LocationPicker from './ANewControls/LocationPicker/LocationPicker';
import { AuthContext } from '../../../utils/context/authContext';
import SaveCurrentSearch from './ANewControls/SaveCurrentSearch/SaveCurrentSearch';

type Props = {
  onChange: (filter: AssignmentFilter) => void;
  values: AssignmentFilter;
};

const filterSections : FilterSection[] = [
  DatePicker,
  DurationPicker,
  ResponsibilitiesPicker,
  PlatformsPicker,
];

const NewFilter: FunctionComponent<Props> = ({ onChange, values }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const windowWidth = useWindowWidth();
  const { isLoggedIn } = useContext(AuthContext);

  let visibleSectionsCount = 1;

  if (windowWidth > 450) {
    visibleSectionsCount = 2;
  }

  if (windowWidth > 550) {
    visibleSectionsCount = 4;
  }

  const visibleSection = filterSections.slice(0, visibleSectionsCount);
  const modalSections = filterSections.slice(visibleSectionsCount, filterSections.length);

  const handleChange = (newValues: AssignmentFilter) => {
    onChange({
      ...values,
      ...newValues,
    });
  };

  return (
    <div className={styles.container}>
      <Row className="g-2 g-lg-3">
        <Col xs="12" md="3" className={styles.item}>
          <LocationPicker onChange={handleChange} values={values} />
        </Col>
        {visibleSection.map((x) => (
          <Col xs="auto" className={styles.item} key={x.name}>
            <FilterButtonWithPopup
              values={values}
              onChange={handleChange}
              section={x}
            />
          </Col>
        ))}
        {modalSections.length > 0 && (
        <Col xs="auto" className={styles.item}>
          <Button onClick={() => setModalShow(true)}>
            Filter
          </Button>
          <FilterModal
            onChange={handleChange}
            values={values}
            components={modalSections}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </Col>
        )}
        {isLoggedIn && (
        <Col xs="auto" className={styles.item}>
          <SaveCurrentSearch />
        </Col>
        )}
      </Row>
    </div>
  );
};

export default NewFilter;
