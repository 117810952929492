/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import styles from './ResponsibilitiesPicker.module.css';
import { ResponsibilitiesFilter } from '../../filterTypes';
import {
  initialState, State, preProcessState, postProcessState,
} from './utils';
import { responsibilities } from '../../../../../utils/responsibilities';
import ResponsibilityItem from './ResponsibilityItem/ResponsibilityItem';
import BulkButton from './BulkButton/BulkButton';
import { FilterSection, FilterSectionComponent, useFilterSection } from '../../useFilter';

const ResponsibilitiesPicker: FilterSectionComponent<ResponsibilitiesFilter> = ({ values }, ref) => {
  const [state, setState] = useState<State>(initialState);
  useFilterSection<ResponsibilitiesFilter>(ref, () => ({
    getValues: () => postProcessState(state),
  }));

  useEffect(() => {
    setState(preProcessState(values));
  }, [values]);

  const setAllTo = (value: boolean | undefined) => {
    if (value === undefined) {
      setState({});
    } else {
      setState(responsibilities.reduce((a, v) => ({ ...a, [v.id]: value }), {}));
    }
  };

  return (
    <div>
      <Row className={styles.bulkActions}>
        <Col xs={12} sm="auto" className={styles.bulkActionsTitle}>Set all to:</Col>
        <Col xs={12} sm="auto">
          <BulkButton onClick={setAllTo} value>Must have</BulkButton>
          <BulkButton onClick={setAllTo} value={undefined}>Don&apos;t mind</BulkButton>
          <BulkButton onClick={setAllTo} value={false}>No</BulkButton>
        </Col>
      </Row>
      <Row className={`${styles.responsibilities} g-2`}>
        {responsibilities.map((x) => (
          <ResponsibilityItem
            key={x.id}
            type={x}
            value={state[x.id]}
            onChange={(value) => setState({ ...state, [x.id]: value })}
          />
        ))}
      </Row>
    </div>
  );
};

const section : FilterSection<ResponsibilitiesFilter> = {
  name: 'responsibilities',
  title: 'Pets',
  popupWidth: 520,
  Component: forwardRef(ResponsibilitiesPicker),
  extractState: (values) => ({
    responsibilitiesYes: values.responsibilitiesYes,
    responsibilitiesNo: values.responsibilitiesNo,
  }),
  shortDescription: ((values) => {
    const yesCount = values.responsibilitiesYes ? values.responsibilitiesYes.length : 0;
    const noCount = values.responsibilitiesNo ? values.responsibilitiesNo.length : 0;
    const filterItemsCount = yesCount + noCount;

    if (filterItemsCount === 0) {
      return null;
    }

    return (
      <>
        Pets
        {filterItemsCount !== 0 && (
        <span>
          {' • '}
          {filterItemsCount}
        </span>
        )}
      </>
    );
  }),
};

export default section;
