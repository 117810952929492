import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styles from './LocationDirectory.module.css';
import WideLayout from '../../WideLayout/WideLayout';
import Layout from '../../Layout/Layout';
import { LocationStatsContainer } from '../locationTypes';
import { locationsData } from '../locationUtils';

const LocationDirectory: FunctionComponent = () => {
  const data = locationsData;

  return (
    <Layout>
      <Helmet>
        <title>Locations directory</title>
      </Helmet>
      <WideLayout>
        <h1>Locations directory</h1>
        Hierarchical directory of house sitting locations.
        <br />
        <br />
        <ul>
          {Object.entries(data).map((x) => (
            <li>
              <Link to={`/locations/${x[0]}/`}>
                {x[1].name}
              </Link>
            </li>
          ))}
        </ul>
      </WideLayout>
    </Layout>
  );
};

export default LocationDirectory;
