import React, { FunctionComponent } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styles from './LocationAssignments.module.css';
import ListView from '../../Assignments/ListView/ListView';
import { locationLinkBuilder } from '../locationUtils';
import { ApiResult, PaginatedAssignments } from '../../../utils/types';
import { LocationData } from '../locationTypes';

type Props = {
  assignments: ApiResult<PaginatedAssignments>;
  location: LocationData;
};

const LocationAssignments: FunctionComponent<Props> = ({ assignments, location }) => (
  <div>
    <h3>
      Latest sits in
      {' '}
      {location.stats.name}
    </h3>
    {(assignments.isLoading || assignments.data === null || assignments.data!.totalResults > 0) ? (
      <>
        {' '}
        <ListView
          assignments={assignments}
          onPageChanged={() => {}}
          currentPage={1}
          withPagination={false}
          withHeader={false}
          useAlternativeSizing
        />
        <div className={styles.sitsButtonContainer}>
          <Link to={locationLinkBuilder(location.continentForFilter, location.country)}>
            <Button variant="primary">
              Browse all sits in
              {' '}
              {location.stats.name}
            </Button>
          </Link>
        </div>
      </>
    ) : (
      <>
        It looks like there are currently no house sits in this area.
        {' '}
        <Link to="/" className={styles.browseAll}>
          <Button variant="primary">
            Browse all international sits
          </Button>
        </Link>
      </>
    )}
  </div>
);

export default LocationAssignments;
