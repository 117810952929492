import { ResponsibilitiesFilter } from '../../filterTypes';
import { ResponsibilityId } from '../../../../../myApi';

export type State = Record<number, boolean | undefined>;
export const initialState : State = {};

// Convert array of numbers to record
export function preProcessState(filter: ResponsibilitiesFilter) : State {
  const internal : State = {};

  if (filter.responsibilitiesYes) {
    filter.responsibilitiesYes.forEach((value) => {
      internal[value] = true;
    });
  }

  if (filter.responsibilitiesNo) {
    filter.responsibilitiesNo.forEach((value) => {
      internal[value] = false;
    });
  }

  return internal;
}

// Convert record of number -> boolean | undefined to an array of numbers
export function postProcessState(internal: State) : ResponsibilitiesFilter {
  const valuesYes : ResponsibilityId[] = [];
  const valuesNo : ResponsibilityId[] = [];

  Object.keys(internal).forEach((key) => {
    const responsibility = key as unknown as ResponsibilityId;
    const value = internal[responsibility];

    if (value !== undefined) {
      if (value) {
        valuesYes.push(responsibility);
      } else {
        valuesNo.push(responsibility);
      }
    }
  });

  return {
    responsibilitiesYes: valuesYes,
    responsibilitiesNo: valuesNo,
  };
}
