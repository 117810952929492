import React, {
  FunctionComponent, useEffect, useRef, useState,
} from 'react';
import {
  Container, Row, RowProps, Spinner,
} from 'react-bootstrap';
import Card from '../Card/Card';
import { ApiResult, PaginatedAssignments } from '../../../utils/types';
import styles from './ListView.module.css';
import Pagination from '../Pagination/Pagination';
import { RESULTS_PER_PAGE } from '../../../utils/constants';
import SavedSearches from '../SavedSearches/SavedSearches';
import AuthOnly from '../../AuthOnly/AuthOnly';

type Props = {
  assignments: ApiResult<PaginatedAssignments>;
  onPageChanged: (page: number) => void;
  currentPage: number;
  withPagination?: boolean;
  withHeader?: boolean;
  useAlternativeSizing?: boolean;
};

const alternativeRowSizing : RowProps = {
  xxl: '4',
  lg: '3',
};

const ListView : FunctionComponent<Props> = ({
  assignments, onPageChanged, currentPage, withPagination, withHeader, useAlternativeSizing,
}) => {
  const myRef = useRef(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollAfterLoaded, setScrollAfterLoaded] = useState(false);

  // @ts-ignore
  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    if (!assignments.isLoading && scrollAfterLoaded) {
      setScrollAfterLoaded(false);
      executeScroll();
      if (containerRef.current) {
        containerRef.current.style.minHeight = 'initial';
      }
    }
  }, [assignments, scrollAfterLoaded]);

  return (
    <div ref={containerRef}>
      {(assignments.isLoading || assignments.data == null)
        ? (
          <div className={styles.spinner}>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )
        : (
          <>
            {withHeader && (
            <AuthOnly>
              <SavedSearches />
            </AuthOnly>
            )}

            {withHeader && (
            <div className={styles.header} ref={myRef}>
              There are currently
              {' '}
              {assignments.data.totalResults}
              {' '}
              sits in this area
            </div>
            )}

            <Row
              xs={1}
              md={2}
              xxl={3}
              xxxl={4}
              className="g-4 gy-4"
              {...(useAlternativeSizing ? alternativeRowSizing : {})}
            >
              {assignments.data.data!.map((assignment) => (
                <Card key={assignment.id} assignment={assignment} />
              ))}
            </Row>
            {withPagination
              && (
              <>
                {' '}
                <Pagination
                  currentPage={currentPage}
                  totalPages={assignments.data.totalPages!}
                  onChange={(number: number) => {
                    if (containerRef.current) {
                      containerRef.current.style.minHeight = `${containerRef.current.clientHeight}px`;
                    }
                    onPageChanged(number);
                    setTimeout(() => setScrollAfterLoaded(true), 50);
                  }}
                />

                <div className={styles.stats}>
                  Showing
                  {' '}
                  {(currentPage - 1) * RESULTS_PER_PAGE + 1}
                  {' - '}
                  {currentPage * RESULTS_PER_PAGE}
                  {' of '}
                  {assignments.data.totalResults!}
                  {' '}
                  places to sit in this area
                </div>
              </>
              )}
          </>
        )}
    </div>
  );
};

ListView.defaultProps = {
  withPagination: true,
  withHeader: true,
  useAlternativeSizing: false,
};

export default ListView;
