import React, { FunctionComponent } from 'react';
import { Button } from 'react-bootstrap';
import styles from './Popup.module.css';

type Props = {
  onClear?: () => void;
  onApply?: () => void;
};

const PopupFooter: FunctionComponent<Props> = ({ onApply, onClear }) => (
  <div className={styles.footer}>
    {onClear !== undefined && <Button onClick={onClear} variant="outline-secondary">Clear</Button>}
    {onApply !== undefined && <Button onClick={onApply} variant="outline-primary">Apply</Button>}
  </div>
);

export default PopupFooter;
