import React, { FunctionComponent } from 'react';
import styles from './Layout.module.css';
import Navbar from './Navbar/Navbar';
import Footer from './Footer/Footer';

type Props = {
  noNavbarBorder?: boolean;
};

const Layout: FunctionComponent<Props> = ({ children, noNavbarBorder }) => (
  <div className={styles.container}>
    <Navbar noBorder={noNavbarBorder} />
    <div className={styles.content}>
      {children}
    </div>
    <Footer />
  </div>
);

Layout.defaultProps = {
  noNavbarBorder: false,
};

export default Layout;
