import Cookies from 'js-cookie';
import React from 'react';

// Taken from https://felixgerschau.com/react-typescript-context/

export interface IAuthContext {
  isLoggedIn: boolean,
  setIsLoggedIn: (isLoggedIn: boolean) => void;
}

export const defaultState = {
  isLoggedIn: Cookies.get('.AspNetCore.Auth') === 'true',
  setIsLoggedIn: () => {},
};

export const AuthContext = React.createContext<IAuthContext>(defaultState);
