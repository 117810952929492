import {
  faCat, faCow, faCrow, faDog, faDuck, faFish, faHorse, faTurtle, faRabbit, faDeerRudolph,
} from '@ondrejnepozitek/pro-solid-svg-icons';
import { ResponsibilityId } from '../myApi';

export type Responsibility = {
  id: ResponsibilityId;
  name: string;
  icon: any;
};

export const responsibilities : Responsibility[] = [
  { id: 1, name: 'Dog(s)', icon: faDog },
  { id: 2, name: 'Cat(s)', icon: faCat },
  { id: 3, name: 'Poultry', icon: faDuck },
  { id: 4, name: 'Bird(s)', icon: faCrow },
  { id: 5, name: 'Fish', icon: faFish },
  { id: 6, name: 'Farm animal(s)', icon: faCow },
  { id: 7, name: 'Horse(s)', icon: faHorse },
  { id: 8, name: 'Reptile(s)', icon: faTurtle },
  { id: 9, name: 'Small pet(s)', icon: faRabbit },
  { id: 10, name: 'Other', icon: faDeerRudolph },
];
