/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { PlatformsFilter } from '../../filterTypes';
import { platforms } from '../../../../../utils/platforms';
import { FilterSection, FilterSectionComponent, useFilterSection } from '../../useFilter';
import {
  initialState, postProcessState, preProcessState, State,
} from './utils';

const PlatformsPicker: FilterSectionComponent<PlatformsFilter> = ({ values }, ref) => {
  const [state, setState] = useState<State>(initialState);
  useFilterSection<PlatformsFilter>(ref, () => ({
    getValues: () => postProcessState(state),
  }));

  useEffect(() => {
    setState(preProcessState(values));
  }, [values]);

  return (
    <div>
      {platforms.map((platform) => (
        <Form.Check
          key={platform.id}
          type="checkbox"
          label={platform.name}
          value={platform.id}
          checked={state[platform.id] || false}
          onChange={(e) => {
            const newState = { ...state };
            newState[platform.id] = e.target.checked;
            setState(newState);
          }}
        />
      ))}
    </div>
  );
};

const section : FilterSection<PlatformsFilter> = {
  name: 'platforms',
  title: 'Websites',
  Component: forwardRef(PlatformsPicker),
  popupWidth: 300,
  extractState: (values) => ({
    websites: values.websites,
  }),
  shortDescription: (values) => {
    const hasValues = values.websites !== undefined;

    if (!hasValues) {
      return null;
    }

    return (
      <>
        Platforms
        {hasValues && (
        <span>
          {' • '}
          {values.websites?.length}
        </span>
        )}
      </>
    );
  },
};

export default section;
